/* eslint-disable import/no-anonymous-default-export */
export default {
    "6247efb86272777b30a7ad65": "Personal Care",
    "6246d3aa209ea4ff4de1bef6": "Food",
    "6247efc66272777b30a7ad6b": "Rent",
    "6247efc06272777b30a7ad68": "Groceries",
    "6247efdc6272777b30a7ad74": "Bills",
    "6247efe06272777b30a7ad77": "Transport",
    "6247efd66272777b30a7ad71": "Entertainment",
    "6247efe96272777b30a7ad7a": "Travel",
    "6247efd06272777b30a7ad6e": "Health & Fitness",
    "6247eff36272777b30a7ad80": "Education",
    "6247f00c6272777b30a7ad8c": "Transfers",
    "6247f0006272777b30a7ad89": "Pets",
    "6247eff86272777b30a7ad83": "Services",
    "6247effd6272777b30a7ad86": "Charity",
    "6247efed6272777b30a7ad7d": "Finance",
    "6249ea02891a3ba4896df13c": "Shopping",
    "62570d9093d8ed88514b4c9b": "Miscellaneous",
    "6247f0136272777b30a7ad8f": "Uncategorized",
};
