import {USER_LOGOUT} from "./actions";


export const userReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGOUT:
            return {};
        case "LOG_IN":
            return {...state, user: action.payload};
        case "SIGN_UP":
            return {...state, newUser: action.payload};
        case "SPAC_SEARCHED_USER":
            return {...state, spacSearchedUser: action.payload};
        case "GOLD_SEARCHED_USER":
            return {...state, goldSearchedUser: action.payload};
        case "HOME_GOLD_TICKETS":
            return {...state, totalGoldTickets: action.payload};
        case "HOME_GOLD_AUM":
            return {...state, totalGoldAum: action.payload};
        case "HOME_ALL_POOLS":
            return {...state, allPools: action.payload};
        default:
            return state;
    }
};

export const logReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGOUT:
            return {};
        case "ACCESS_LOGS":
            return {...state, accessLogs: action.payload};
        case "API_LOGS":
            return {...state, apiLogs: action.payload};
        case "API_LOGS_PREAUTH":
            return {...state, preAuthPair: action.payload};
        case "RESPONSE_TIME_LOGS":
            return {...state, responseTime: action.payload};
        case "GOLD_API_LOGS":
            return {...state, goldApiLogs: action.payload};
        default:
            return state;
    }
};