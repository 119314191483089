import React, {useEffect, useState} from "react";
import styled from "styled-components";
import DataTable from 'react-data-table-component';
/*
columns
{
{
        name: 'Route',
        selector: row => row.route,
    },{
        name: 'Status',
        selector: row => row.statu,
    },{
        name: 'Timestamp',
        selector: row => row.timestamp,
    },
}
data{
    {
        id: 1,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
    },
}
*/
function ErrorLogs(props) {
    const [data,setData]=useState();
    useEffect(() => {
        var logs={};
        const result = props.s3Logs.split(/\r?\n/);
        result.pop();
        for (let i in result) {
            let line = JSON.parse(result[i]);
            let splitMessage = line.message.split(" for ");
            if(logs.hasOwnProperty(splitMessage[0])){
                logs[splitMessage[0]].push({timestamp:line.timestamp})
            }else{
                logs[splitMessage[0]]=[{timestamp:line.timestamp}]

            }
        }
        console.log(logs);
    }, [props.s3Logs]);
    return <>asdaasdwd</>;
}

export default ErrorLogs;
