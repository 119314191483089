import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as MdIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";
// import { ROLES } from "../../../data/roles";
const {REACT_APP_ROLES} = process.env;

const ROLES = JSON.parse(REACT_APP_ROLES);
export const NavbarData = [
    {
        title: "Home",
        path: "/app",
        roles: [parseInt(ROLES.Admin), parseInt(ROLES.Support),parseInt(ROLES.PayoutAdmin)],
        icon: <AiIcons.AiFillHome />,
    },
    {
        title: "Logs",
        path: "#",
        roles: [parseInt(ROLES.Admin), parseInt(ROLES.Analytics)],
        icon: <FaIcons.FaFileCode />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        subNav: [
            {
                roles: [parseInt(ROLES.Admin), parseInt(ROLES.Analytics)],
                title: "Access",
                path: "/app/logs/access",
            },
            {
                roles: [parseInt(ROLES.Admin), parseInt(ROLES.Analytics)],
                title: "Api",
                path: "/app/logs/api",
            },
            {
                roles: [parseInt(ROLES.Admin), parseInt(ROLES.Analytics)],
                title: "PreAuth Pair Validate",
                path: "/app/logs/preauthpaircheck",
            },
            {
                roles: [parseInt(ROLES.Admin), parseInt(ROLES.Analytics)],
                title: "Gold Api",
                path: "/app/logs/gold",
            },
            
            // {
            //     roles: [parseInt(ROLES.Admin), parseInt(ROLES.Analytics)],
            //     title: "Response Time",
            //     path: "/app/logs/responsetime",
            // }
        ]
    },
    {
        title: "Support",
        path: "#",
        roles: [parseInt(ROLES.Admin), parseInt(ROLES.Support),parseInt(ROLES.PayoutAdmin)],
        icon: <BiIcons.BiSupport />,

        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        subNav: [
            {
                roles: [parseInt(ROLES.Admin), parseInt(ROLES.Support)],
                title: "Spac User",
                path: "/app/support/getspacuser",
            },
            {
                roles: [parseInt(ROLES.Admin), parseInt(ROLES.Support)],
                title: "Gold User",
                path: "/app/support/getgolduser",
            },
            // {
            //     // roles: [parseInt(ROLES.Payout)],
            //     roles: [parseInt(ROLES.PayoutAdmin)],
            //     title: "Refund User",
            //     path: "/app/support/refundUser",
            // },
            {
                roles: [parseInt(ROLES.Admin), parseInt(ROLES.Analytics)],
                title: "Gold Deleted Accounts",
                path: "/app/support/deletedaccounts",
            }
        ]
    },
];
