import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DarkColors, boxAround } from "../../../assets/styles/sharedCss";
import { useDispatch, useSelector } from "react-redux";
import GoldPeIcon from "../../../assets/images/GoldPe-Icon.png";
import DataTable from "react-data-table-component";
import axios from "axios";
import ClipLoader from "react-spinners/SyncLoader";
import { HOME_GOLD_AUM, HOME_GOLD_TICKETS, HOME_ALL_POOLS } from "../../../redux/actions";
const { REACT_APP_API_URL } = process.env;
const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const Home = () => {
    const conditionalRowStyles = [
        {
            when: (row) => row.level === "error",
            style: {
                backgroundColor: `#b8000053`,
                color: "white",
            },
        },
    ];
    var tables = 0;
    const dispatch = useDispatch();
    const token = useSelector((state) => state.userResponse?.user?.token);
    const totalAum = useSelector((state) => state.userResponse?.totalGoldAum?.data);
    const totalTickets = useSelector((state) => state.userResponse?.totalGoldTickets?.data);
    const allPools = useSelector((state) => state.userResponse?.allPools?.data);
    const [aum, setAum] = useState({});
    const [aumLoading, setAumLoading] = useState(false);
    const [tickets, setTickets] = useState({});
    const [ticketsLoading, setTicketsLoading] = useState(false);
    const [pools, setPools] = useState([]);
    const [poolsLoading, setPoolsLoading] = useState(false);
    useEffect(() => {
        if (totalAum != null || totalAum !== undefined) {
            setAum(totalAum);
        }
    }, [totalAum]);
    useEffect(() => {
        if (totalTickets != null || totalTickets !== undefined) {
            setTickets(totalTickets);
        }
    }, [totalTickets]);
    useEffect(() => {
        if (allPools != null || totalTickets !== undefined) {
            setPools(allPools);
        }
    }, [totalTickets]);
    const aumFetch = async (e) => {
        setAumLoading(true);
        await axios({
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            url: REACT_APP_API_URL + "/adminPanel/gold/aum",
            params: {},
        })
            .then((res) => {
                setAumLoading(false);
                var data = res.data;
                if (data.status === "success") {
                    var result = data.message;
                    var totalGold = 0;
                    var rewardedInr = 0;
                    result.map((record) => {
                        if (record.type === "Sell") {
                            totalGold -= record.qty;
                        } else if (record.type === "Buy") {
                            totalGold += record.qty;
                        } else if (record.type === "Reward") {
                            rewardedInr += record.amount;
                        }
                    });
                    setAum({ totalGold, rewardedInr });
                    dispatch({ type: HOME_GOLD_AUM, payload: { data: { totalGold, rewardedInr } } });
                } else {
                    // setError("aaa");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const fetchPools = async (e) => {
        setPoolsLoading(true);
        await axios({
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            url: REACT_APP_API_URL + "/adminPanel/gold/pools",
            params: {},
        })
            .then((res) => {
                setPoolsLoading(false);
                var data = res.data;
                if (data.status === "success") {
                    var poolData = data.message;
                    poolData.map((pool) => {
                        let actualUser = 0;
                        pool.activeUserCount = pool.activeUsers.length;
                        pool.activeUsers.map((poolUser) => {
                            if (poolUser.name !== "GBot") {
                                actualUser++;
                            }
                        });
                        pool.actualUserCount = actualUser;
                        delete pool.activeUsers;
                    });
                    setPools(poolData);
                    dispatch({ type: HOME_ALL_POOLS, payload: { data: poolData } });
                } else {
                    // setError("aaa");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const ticketsDateFetch = async (e) => {
        setTicketsLoading(true);
        await axios({
            method: "get",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            url: REACT_APP_API_URL + "/adminPanel/gold/tickets",
            params: {},
        })
            .then(async (res) => {
                setTicketsLoading(false);
                var data = res.data;
                if (data.status === "success") {
                    var result = data.message;
                    setTickets(result);
                    dispatch({ type: HOME_GOLD_TICKETS, payload: { data: result } });
                } else {
                    console.log(data.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    function getTable(cols) {
        tables++;

        const columns = [
            {
                name: "Pool Name",
                selector: (row) => row.poolName,
                sortable: true,
            },
            {
                name: "Total Users",
                selector: (row) => row.activeUserCount,
                sortable: true,
            },
            {
                name: "Actual Users",
                selector: (row) => row.actualUserCount,
                sortable: true,
            },
            {
                name: "Tickets",
                selector: (row) => row.activeTicketCount,
                sortable: true,
            },
            {
                name: "Creator Id",
                selector: (row) => row.creator,
                sortable: true,
            },
            {
                name: "Winnings",
                selector: (row) => row.currentWinnings,
                sortable: true,
            },
        ];
        return (
            <>
                <DataTable
                    columns={columns}
                    // data={cols.filter((item) => {
                    //     if (filter === "") {
                    //         return item;
                    //     } else if (item.identifier.toLowerCase().includes(filter.toLowerCase())) {
                    //         return item;
                    //     }
                    // })}
                    data={cols}
                    pagination
                    theme="solarized"
                    key={tables}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="300px"
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    conditionalRowStyles={conditionalRowStyles}
                    dense
                />
            </>
        );
    }
    return (
        <Container>
            <div className="container_first_row">
                <AumCard style={boxAround} onClick={aumFetch}>
                    {aumLoading ? (
                        <ClipLoader color={`rgb(${DarkColors.themeGold})`} loading={aumLoading} size={20} />
                    ) : (
                        <>
                            <div>
                                <p>AUM</p>
                                <h1>{aum?.totalGold?.toFixed(4) || 0}gm</h1>
                                <p>Reward Distributed</p>
                                <h1>₹{aum?.rewardedInr || 0}</h1>
                            </div>
                            <img src={GoldPeIcon} className="goldPeIcon" alt="Icon" />
                        </>
                    )}
                </AumCard>
                <Tickets style={boxAround} onClick={ticketsDateFetch}>
                    {ticketsLoading ? (
                        <ClipLoader color={`rgb(${DarkColors.themeGold})`} loading={ticketsLoading} size={20} />
                    ) : (
                        <>
                            <div>
                                <p>Tickets</p>
                                <div className="tickets">
                                    {tickets &&
                                        Object.entries(tickets).map(([key, value]) => {
                                            return (
                                                <span
                                                    key={key + "ticket"}
                                                    className={key}
                                                    onMouseOver={() => {
                                                        document.getElementById(`${key}Data`).style.display = "block";
                                                    }}
                                                    onMouseOut={() => {
                                                        document.getElementById(`${key}Data`).style.display = "none";
                                                    }}
                                                >
                                                    -{value.Total || 0}-
                                                </span>
                                            );
                                        })}
                                    {tickets &&
                                        Object.entries(tickets).map(([key, value]) => {
                                            return (
                                                <div key={key + "data"} className={`Data ${key}Data`} id={`${key}Data`}>
                                                    {tickets && (
                                                        <div>
                                                            Buy Gold:{value["Buy Gold"]}, Referral:{value["Referral"]}, Bonus:{value["Bonus"]}, Joining Bonus:
                                                            {value["Joining Bonus"]}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </>
                    )}
                </Tickets>
            </div>

            <div className="container_second_row">
                <h2 onClick={fetchPools}>Click to fetch pools - {!poolsLoading && <>Total :{pools?.length}</>}</h2>
                <Pools>
                    {poolsLoading ? (
                        <div>Fetch pools</div>
                    ) : (
                        <>
                            {/* <div style={boxAround}></div> */}
                            {/* {pools.map((pool) => {
                                return (
                                    <PoolCard style={boxAround} key={pool._id}>
                                        <div>{pool.poolName}</div>
                                        <div>{pool.activeTicketCount}</div>
                                        <div>{pool.activeUsers.length}</div>
                                        <div>{pool.creator}</div>
                                        <div>{pool.currentWinnings}</div>
                                    </PoolCard>
                                );
                            })} */}
                            {getTable(allPools)}
                        </>
                    )}
                </Pools>
            </div>
        </Container>
    );
};

export default Home;

const Container = styled.div`
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow-y: auto;
    .container_first_row {
        flex: 1;
        flex-wrap: wrap;
        gap: 40px;
        display: flex;
        flex-direction: row;
    }
    .container_second_row {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
`;
const AumCard = styled.div`
    width: 300px;
    padding: 40px 40px !important;
    height: 100px;
    gap: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    :hover {
        cursor: pointer;
        opacity: 0.8;
    }
    div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        p {
            font-weight: 400;
            font-size: 1.2rem;
            opacity: 0.6;
        }
        h1 {
            font-weight: bold;
            font-size: 2rem;
        }
    }
    .goldPeIcon {
        height: 70px;
    }
`;

const Tickets = styled.div`
    width: fit-content;
    padding: 40px 40px !important;
    height: 100px;
    gap: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    :hover {
        cursor: pointer;
        opacity: 0.8;
    }
    div {
        display: flex;
        flex-direction: column;
        gap: 5px;
        p {
            font-weight: 400;
            font-size: 1.2rem;
            opacity: 0.6;
        }
        .tickets {
            font-weight: bold;
            display: flex;
            flex-direction: row;
            font-size: 2rem;
            position: relative;
            span {
                cursor: pointer;
                z-index: 10;
                font-weight: 400;
                font-size: 2rem;
            }
            .Data {
                display: none;
                top: 40px;
                div {
                    padding: 5px 10px;
                    background-color: rgba(0, 0, 0, 1);
                    border-radius: 10px;
                    font-size: 1rem;
                }
                width: max-content;
                position: absolute;
            }
            .Active {
                color: #10db10e6;
            }
            .Inactive {
                color: #dbd810e6;
            }
            .Expired {
                color: #db3f10e6;
            }
        }
    }
    .goldPeIcon {
        height: 70px;
    }
`;

const Pools = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
`;

const PoolCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;
