import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import {DarkColors, boxAround} from "../../../assets/styles/sharedCss";
import DataTable, {defaultThemes} from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
const {REACT_APP_API_URL} = process.env;
const ExpandedComponent = ({data}) => <pre>{JSON.stringify(data, null, 2)}</pre>;

function PreAuthPair() {
    const logString = useSelector((state) => state.logResponse.preAuthPair?.data);
    const [allLogs, setAllLogs] = useState();
    const [decentroLog, setDecentroLog] = useState();
    const [pairs, setPairs] = useState();
    var tables = 1;
    const [noPair, setNoPair] = useState();
    const [rejectTxn, setRejectTxn] = useState();
    useEffect(() => {
        logString && combineAllogs();
    }, [logString]);
    useEffect(() => {
        allLogs && fetchDecentroLogs();
    }, [allLogs]);
    useEffect(() => {
        decentroLog && computeLogs();
    }, [decentroLog]);
    function combineAllogs() {
        //Looping through each days of logs
        var logObj = JSON.parse(logString);
        var tempAllLogs = [];
        for (let day in logObj) {
            let eachDayLog = logObj[day].split(/\r?\n/);
            eachDayLog.pop(); // to remove last element since its always empty
            tempAllLogs.push(...eachDayLog);
        }
        setAllLogs(tempAllLogs);
    }
    function fetchDecentroLogs() {
        let tempLogStore = [];
        for (let n in allLogs) {
            let log = JSON.parse(allLogs[n]); // parsing each log since its stored as a string
            if (log.level === "info") {
                let [service, controller, method, identifier] = log.message.split(".");
                if (
                    service === "DECENTRO" &&
                    controller === "CALLBACK" &&
                    (method === "preauth" ||
                        method === "preauthV2" ||
                        method === "paymentNotification" ||
                        method === "paymentNotificationV2" ||
                        method === "walletReversal" ||
                        method === "walletReversalV2")
                ) {
                    tempLogStore.push({level: log.level, method, identifier, request: log.request, timestamp: log.timestamp, response: log.response});
                }
            }
        }
        setDecentroLog(tempLogStore);
    }
    function computeLogs() {
        let pairNotFound = [];
        let pairFound = [];
        let rejectedTxn = [];
        for (let n in decentroLog) {
            if (decentroLog[n].method === "preauth" || decentroLog[n].method === "preauthV2") {
                let pair = decentroLog.filter((log) => {
                    if (log.method === "paymentNotification" || log.method === "paymentNotificationV2"|| log.method === "walletReversal"|| log.method === "walletReversalV2") {
                        if (log.request.retrievalReferenceNumber === decentroLog[n].request.retrievalReferenceNumber) {
                            return log;
                        }
                    }
                });
                if (pair.length) {
                    pairFound.push({preauth: decentroLog[n], preauthNotification: pair[0]});
                } else {
                    try {
                        if (decentroLog[n].response.apiLoggerResponse.split(" ")[0] === "Approved") {
                            pairNotFound.push(decentroLog[n]);
                        } else {
                            rejectedTxn.push(decentroLog[n]);
                        }
                    } catch (error) {
                        console.log(decentroLog[n]);
                    }
                }
            }
        }
        setRejectTxn(rejectedTxn);
        setNoPair(pairNotFound);
        setPairs(pairFound);
    }
    function getTable(cols) {
        tables++;
        var columns;
        if (cols[0]?.preauth) {
            columns = [
                {
                    name: "TimeStamp",
                    selector: (row) => row.preauth.timestamp,
                    sortable: true,
                },
                {
                    name: "Identifier",
                    selector: (row) => row.preauth.identifier,
                    sortable: true,
                },
                {
                    name: "Level",
                    selector: (row) => row.preauth.level,
                    sortable: true,
                },
            ];
        } else {
            columns = [
                {
                    name: "TimeStamp",
                    selector: (row) => row.timestamp,
                    sortable: true,
                },
                {
                    name: "Identifier",
                    selector: (row) => row.identifier,
                    sortable: true,
                },
                {
                    name: "Level",
                    selector: (row) => row.level,
                    sortable: true,
                },
            ];
        }
        return (
            <>
                <DataTable
                    columns={columns}
                    data={cols}
                    pagination
                    theme="solarized"
                    key={tables}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="300px"
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    dense
                />
            </>
        );
    }
    function dropDownVendor(e) {
        let routeCollumn = document.getElementById(e);
        let classes = routeCollumn.className.split(" ");
        if (classes[0] === "showRoute") {
            routeCollumn.classList.remove("showRoute");
        } else {
            routeCollumn.classList.add("showRoute");
        }
    }
    return (
        <PreAuthPairWrapper>
            <Container>
                {noPair && rejectTxn && pairs && (
                    <>
                        <NoPairContainer style={boxAround}>
                            {noPair && (
                                <div>
                                    <h2 className="heading" onClick={(e) => dropDownVendor("noPair")}>
                                        Approved & Pair Missing
                                    </h2>
                                    <div className="" id="noPair">
                                        {getTable(noPair)}
                                    </div>
                                </div>
                            )}
                        </NoPairContainer>
                        <RejectedTxnContainer style={boxAround}>
                            {rejectTxn && (
                                <div>
                                    <h2 className="heading" onClick={(e) => dropDownVendor("rejectTxn")}>
                                        Rejected Transactions
                                    </h2>
                                    <div className="" id="rejectTxn">
                                        {getTable(rejectTxn)}
                                    </div>
                                </div>
                            )}
                        </RejectedTxnContainer>
                        <PairedContainer style={boxAround}>
                            {pairs && (
                                <div>
                                    <h2 className="heading" onClick={(e) => dropDownVendor("pairs")}>
                                        Paired Logs
                                    </h2>
                                    <div className="showRoute" id="pairs">
                                        {getTable(pairs)}
                                    </div>
                                </div>
                            )}
                        </PairedContainer>
                    </>
                )}
            </Container>
        </PreAuthPairWrapper>
    );
}

export default PreAuthPair;

const PreAuthPairWrapper = styled.div`
    flex: 1;
`;
const Container = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .heading {
        color: rgba(${DarkColors.themeFont}, 0.7);
        border-radius: 8px;
        padding: 10px;
        font-weight: 700;
        font-size: 1.2rem;
        :hover {
            background-color: rgba(${DarkColors.themeBlue}, 0.1);
            cursor: pointer;
        }
    }
    .showRoute {
        display: none !important;
    }
`;
const NoPairContainer = styled.div``;
const RejectedTxnContainer = styled.div``;
const PairedContainer = styled.div``;
