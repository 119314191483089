import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Cookies from "universal-cookie";
import axios from "axios";
import { useDispatch } from "react-redux";
import { LOG_IN } from "../redux/actions";
import zbLogo from "../assets/images/ZeroBalance-logo.png";
import { DarkColors } from "../assets/styles/sharedCss";
import { useRef } from "react";
import { socket, socketConnect } from "../utils/socketIo";
const CryptoJS = require("crypto-js");
const { REACT_APP_API_URL } = process.env;

function Login() {
    const cookie = new Cookies();
    const dispatch = useDispatch();
    const accessToken = cookie.get("auth");
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [mpin, setMpin] = useState("");
    const [otp, setOtp] = useState("");
    const [error, setError] = useState("");
    const [otpMenu, setOtpMenu] = useState(false);
    const otpToken = useRef("");
    const onSubmit = async (e) => {
        e.preventDefault();
        if (!otpMenu) {
            if (email.trim() && mpin.trim()) {
                let loginValues = { email, mpin: CryptoJS.MD5(mpin).toString() };
                // let loginValues = {email, mpin};
                await axios({
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    url: REACT_APP_API_URL + "/adminPanel/login",
                    data: loginValues,
                }).then((res) => {
                    let response = res.data;
                    if (response.message === "Invalid") {
                        setError("Please provide credentials.");
                    } else if (response.message === "Unuthorized") {
                        setError("You are not authorized to login.");
                    } else if (response.message === "Invalid login attempt") {
                        setError("Credentials not matching");
                    } else if (response.status === "success") {
                        otpToken.current = response.message.otpToken;
                        setOtpMenu(true);
                    }
                });
            } else {
                setError("Please provide credentials.");
            }
        } else if (otpMenu) {
            if (email.trim() && mpin.trim() && otp.trim()) {
                let loginValues = { email, mpin: CryptoJS.MD5(mpin).toString(), otp, otpToken: otpToken.current };
                // let loginValues = {email, mpin};
                await axios({
                    method: "post",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    url: REACT_APP_API_URL + "/adminPanel/otpVerify",
                    data: loginValues,
                }).then((res) => {
                    let response = res.data;
                    if (response.status === "failure") {
                        setError(response.message);
                    } else if (response.status === "success") {
                        let token = response.message.token;
                        let roles = response.message.roles;
                        cookie.set("auth", token);
                        dispatch({ type: LOG_IN, payload: { token, roles } });
                        navigate("/app");
                    }
                });
            } else {
                setError("Please provide credentials.");
            }
        }
    };

    return (
        <>
            <LoginWrapper>
                <Container>
                    <Img src={zbLogo} />
                    <div className="form__group field">
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            className="form__field"
                            placeholder="Email"
                            name="email"
                            id="Email"
                            value={email}
                            // required
                        />
                        <label htmlFor="Email" className="form__label">
                            Email
                        </label>
                    </div>
                    <div className="form__group field">
                        <input
                            onChange={(e) => setMpin(e.target.value)}
                            type="password"
                            className="form__field"
                            placeholder="Mpin"
                            name="Mpin"
                            id="Mpin"
                            value={mpin}

                            // required
                        />
                        <label htmlFor="Mpin" className="form__label">
                            Mpin
                        </label>
                    </div>

                    {otpMenu && (
                        <div className="form__group field">
                            <input
                                onChange={(e) => setOtp(e.target.value)}
                                type="text"
                                className="form__field"
                                placeholder="otp"
                                name="otp"
                                id="otp"
                                value={otp}

                                // required
                            />
                            <label htmlFor="otp" className="form__label">
                                Enter Otp
                            </label>
                        </div>
                    )}
                    <span>{error}</span>
                    <button onClick={onSubmit}>{otpMenu ? "Login" : "Send OTP"}</button>
                </Container>
            </LoginWrapper>
        </>
    );
}

export default Login;

const LoginWrapper = styled.div`
    color: white;
    width: 100vw;
    height: 100vh;
    background-color: rgb(9, 9, 10);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.form`
    min-width: 300px;
    padding: 30px;
    display: flex;
    margin-top: -100px;
    gap: 10px;
    flex-direction: column;
    text-align: center;
    .form__group {
        font-family: Lato, serif;
        position: relative;
        padding: 15px 0 0;
        /* margin-top: 10px; */
    }
    .form__field {
        font-family: inherit;
        width: 100%;
        border: 0;
        border-bottom: 2px solid rgb(229, 165, 255);
        outline: 0;
        font-size: 1.3rem;
        color: rgb(229, 165, 255);
        padding: 7px 0;
        background: transparent;
        transition: border-color 0.2s;

        &::placeholder {
            color: transparent;
        }

        &:placeholder-shown ~ .form__label {
            font-size: 1.3rem;
            cursor: text;
            top: 20px;
        }
    }
    .form__label {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-weight: 700;

        color: white;
    }

    .form__field:focus {
        ~ .form__label {
            position: absolute;
            top: 0;
            display: block;
            transition: 0.2s;
            color: white;
            font-weight: 700;
        }
        padding-bottom: 6px;
        font-weight: 700;
        border-width: 3px;
        border-image: linear-gradient(to right, #ba17fa, rgb(229, 165, 255));
        border-image-slice: 1;
    }
    /* reset input */
    .form__field {
        &:required,
        &:invalid {
            box-shadow: none;
        }
    }
    button {
        background-color: rgb(${DarkColors.themeBlue});
        border: 0;
        padding: 10px;
        color: white;
        font-size: 1.3rem;
        font-weight: 700;
    }
`;
const Img = styled.img`
    margin-bottom: 30px;
`;
