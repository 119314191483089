export const LOG_IN = "LOG_IN";
export const USER_LOGOUT = "USER_LOGOUT"
export const SPAC_SEARCHED_USER = "SPAC_SEARCHED_USER"
export const GOLD_SEARCHED_USER = "GOLD_SEARCHED_USER"
export const ACCESS_LOGS = "ACCESS_LOGS"
export const API_LOGS = "API_LOGS"
export const API_LOGS_PREAUTH = "API_LOGS_PREAUTH"
export const RESPONSE_TIME_LOGS = "RESPONSE_TIME_LOGS"
export const GOLD_API_LOGS = "GOLD_API_LOGS"
export const HOME_GOLD_AUM = "HOME_GOLD_AUM"
export const HOME_GOLD_TICKETS = "HOME_GOLD_TICKETS"
export const HOME_ALL_POOLS = "HOME_ALL_POOLS"



