import React from "react";
import io from "socket.io-client";

var socket;
const socketConnect = (token) => {
    // socket = io.connect("https://backend.zerobalance.club:3001", {query: {token}});
    // socket = io.connect("https://dev.zerobalance.club:5000", {query: {token,instance:"Gold"}});
    // socket = io.connect("https://preprod.zerobalance.club:5000", {
    //     query: {
    //         instance: "Gold",
    //         token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGU3NTMwZWIzZGU5NmM1ZGQzY2E1YzQiLCJpYXQiOjE2OTQ3MDI0NDcsImV4cCI6MTY5NDcwNDI0N30.o0rNd4iTMICZ4Gy_OnmgBDlNqbpvUaRPxhmQJA7ECnw",
    //     },
    // });
    // socket = io.connect("http://localhost:3001", {
    //     query: {
    //         instance: "Gold",
    //         token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGU3NTMwZWIzZGU5NmM1ZGQzY2E1YzQiLCJpYXQiOjE2OTQ2ODQwMTUsImV4cCI6MTY5NDY4NTgxNX0.wEVSQPmtVqsCGx7ltoDHicHAIbkC1u_se2HET-WZyWc",
    //     },
    // });
    // socket = io.connect("http://localhost:3001", {query: {token}});
};

export { socket, socketConnect };
