import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { DarkColors, boxAround } from "../../../assets/styles/sharedCss";
import DataTable, { defaultThemes } from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { enIN } from "date-fns/locale";
import moment from "moment";
ChartJS.register(...registerables);

function AccessLogs() {
    const logString = useSelector((state) => state.logResponse.accessLogs?.data);
    const [logObj, setLogObj] = useState();
    useEffect(() => {
        logString && setLogObj(JSON.parse(logString));
    }, [logString]);

    var computedData = useMemo(() => {
        if (logObj) {
            let chartData = { Gold: new Array(800).fill(0) };
            let chartLabel = [];
            var barChartLabel = [];
            var barChartData = [];
            var barData = {};

            for (let n in logObj) {
                let allLogs = logObj[n].split(/\r?\n/);
                allLogs.pop(); // to remove last element since its always empty
                for (let log in allLogs) {
                    let totalCount = 0;
                    var dateHour = "";
                    log = JSON.parse(allLogs[log]);
                    const paths = ["adminPanel", "SERVER"];
                    // const paths = ["auth", "category", "limit", "register", "payment", "statement", "merchant", "download", "adminPanel", "SERVER", "upi", "user", "funding", "global", "gold"];
                    const goldPaths = ["gold", "Ticket"];
                    let [method, routeQuery, ...identifier] = log.message.split(".");
                    let [route, query] = routeQuery.split("?");
                    let originalPath = route.split("/");
                    // console.log(originalPath, method);
                    // if (paths.includes(route) || paths.includes(originalPath[1])) {
                    // console.log("In");
                    identifier = identifier.toString().replace(/,/g, ".");
                    // Logic for chartData
                    // Storing every single hour of logs per day wise.
                    let logTime = log.timestamp.split(":");
                    let minuteInt = parseInt(logTime[1]);
                    // dateHour = logTime[0] + ":" + (minuteInt<30?"00":"30") +":00 "+ logTime[2].split(" ")[1]; // Chart by 30 min interval
                    dateHour =
                        logTime[0] +
                        ":" +
                        (minuteInt < 10 ? "00" : minuteInt < 20 ? "10" : minuteInt < 30 ? "20" : minuteInt < 40 ? "30" : minuteInt < 50 ? "40" : "50") +
                        ":00 " +
                        logTime[2].split(" ")[1]; // Chart by 10 min interval
                    let reverDateFormat = dateHour.split("/");
                    dateHour = reverDateFormat[1] + "/" + reverDateFormat[0] + "/" + reverDateFormat[2];
                    dateHour = dateHour.replace(",", "");

                    if (!chartLabel.includes(dateHour)) {
                        chartLabel.push(dateHour);
                    }
                    if (chartData.hasOwnProperty(route)) {
                        totalCount++;
                        if (chartData[route][chartLabel.indexOf(dateHour)]) {
                            chartData[route][chartLabel.indexOf(dateHour)] += 1;
                        } else {
                            chartData[route][chartLabel.indexOf(dateHour)] = 1;
                        }
                    } else {
                        totalCount++;
                        chartData[route] = [];
                        chartData[route][chartLabel.indexOf(dateHour)] = 1;
                    }
                    // if (goldPaths.includes(originalPath[1])) {
                    chartData["Gold"][chartLabel.indexOf(dateHour)] += totalCount;
                    if (barData[identifier]) {
                        barData[identifier]++;
                    } else {
                        barData[identifier] = 1;
                    }
                    // } else {
                    // chartData["Spac"][chartLabel.indexOf(dateHour)] += totalCount;
                    // }
                    // }
                }
            }
            let sortable = [];
            for (var no in barData) {
                sortable.push([no, barData[no]]);
            }

            sortable = sortable
                .sort(function (a, b) {
                    return a[1] - b[1];
                })
                .slice(-50);
            sortable.map(([key, value]) => {
                barChartLabel.push(key);
                barChartData.push(value);
            });
            return {
                lineChart: {
                    chartData,
                    chartLabel,
                },
                barChart: {
                    barChartLabel,
                    barChartData,
                },
            };
        }
    }, [logObj]);

    const canvasData = (data) => {
        try {
            setTimeout(() => {
                const ctx = document.getElementById("lineChart").getContext("2d");
            }, 500);
        } catch (error) {
            console.log(error.toString());
        }
        function dataSetFunc() {
            let sets = [];
            for (let s in data.chartData) {
                if (s === "Spac") {
                    sets.push({
                        label: s,
                        borderColor: `rgba(${DarkColors.themeGreen},0.7)`,
                        pointRadius: 3,
                        lineTension: 0,
                        borderWidth: 2.5,
                        data: data.chartData[s],
                    });
                } else if (s === "Gold") {
                    sets.push({
                        label: s,
                        borderColor: `rgba(${DarkColors.themeGold},0.7)`,
                        pointRadius: 3,
                        lineTension: 0,
                        borderWidth: 2.5,
                        data: data.chartData[s],
                    });
                } else {
                    sets.push({
                        label: s,
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        lineTension: 0,
                        borderWidth: 0,
                        data: data.chartData[s],
                    });
                }
            }
            return sets;
        }
        return {
            labels: data.chartLabel,
            datasets: dataSetFunc(),
        };
    };
    const canvasOptions = (color) => {
        return {
            scales: {
                y: { grid: { lineWidth: "1", color: `rgba(${color},0.2)` }, ticks: { color: `rgba(${DarkColors.themeFont},0.4)` } },
                x: {
                    type: "time",
                    time: {
                        unit: "hour",
                    },
                    adapters: {
                        date: {
                            locale: enIN,
                        },
                    },
                    grid: { lineWidth: "1", color: `rgba(${color},0.2)` },
                    ticks: { color: `rgba(${DarkColors.themeFont},0.4)` },
                },
            },
            maintainAspectRatio: false,
            title: {
                display: true,
                text: "Total api calls",
                fontSize: 20,
            },

            interaction: {
                intersect: false,
                mode: "index",
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    // Disable the on-canvas tooltip
                    enabled: false,
                    events: ["click"],
                    itemSort: (a, b, data) => b.raw - a.raw,
                    external: function (context) {
                        // Tooltip Element
                        let tooltipEl = document.getElementById("chartjs-tooltip");

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement("div");
                            tooltipEl.id = "chartjs-tooltip";
                            tooltipEl.innerHTML = "<table></table>";
                            document.getElementById("chartjs-box").appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        const tooltipModel = context.tooltip;
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const bodyLines = tooltipModel.body.map(getBody);
                            let innerHtml = "<thead>";
                            innerHtml += "<tr><th>" + tooltipModel.title[0] + "</th></tr>";
                            innerHtml += "</thead><tbody>";

                            bodyLines.forEach(function (body, i) {
                                innerHtml +=
                                    `<tr><td><span ${
                                        body[0].split(":")[0] === "Spac"
                                            ? `style="color:rgb(${DarkColors.themeGreen})"`
                                            : body[0].split(":")[0] === "Gold"
                                            ? `style="color:rgb(${DarkColors.themeGold})"`
                                            : ""
                                    }>` +
                                    body +
                                    "</span></td></tr>";
                            });
                            innerHtml += "</tbody>";

                            let tableRoot = tooltipEl.querySelector("table");
                            tableRoot.innerHTML = innerHtml;
                        }
                    },
                },
            },
        };
    };
    const barData = (data) => {
        return {
            labels: data.barChartLabel,
            datasets: [
                {
                    label: "Api Hit",
                    data: data.barChartData,
                    backgroundColor: [`rgba(${DarkColors.themeRed},0.4)`],
                    barThickness: 25,
                    borderWidth: 5,
                    borderColor: "rgba(0,0,0,0)",
                    barPercentage: 1,
                },
            ],
        };
    };
    const options = {
        indexAxis: "y",

        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: true,
                text: "IP Hits",
            },
        },
        maintainAspectRatio: true,
    };
    return (
        <>
            <LogsWrapper>
                {logObj && (
                    <>
                        <Container>
                            <ApiCallChart id="chartjs-box">
                                <div style={boxAround} id="lineChartDiv">
                                    <Line id="lineChart" data={canvasData(computedData.lineChart)} options={canvasOptions(DarkColors.themeGreen)} />
                                </div>
                            </ApiCallChart>
                            <IpHitBarChart style={boxAround}>
                                <Bar id="barChart" data={barData(computedData.barChart)} options={options} height={"200%"} />
                            </IpHitBarChart>
                        </Container>
                    </>
                )}
            </LogsWrapper>
        </>
    );
}
export default AccessLogs;

const LogsWrapper = styled.div``;
const Container = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    #chartjs-tooltip {
        border: 1px solid rgba(${DarkColors.themeBlue}, 0.3);
        padding: 15px 20px;
        background-color: rgb(${DarkColors.themeBackgroundSemiDark});
        border-radius: 10px;
        box-shadow: rgba(${DarkColors.themeShadow}, 25%) 0px 1px 15px 0px;
    }
`;
const IpHitBarChart = styled.div`
    min-height: 30vh;
`;

const ApiCallChart = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    #lineChartDiv {
        min-height: 30vh;
    }
`;
