import { createTheme } from "react-data-table-component";

// export const DarkColors = {
//     themeBackgroundDark: `23,23,23`,
//     themeBackgroundSemiDark: `35,35,35`,
//     themeSemiLight: `197,82,235`,
//     themeFont: `238, 238, 238`,
// };

/* export const DarkColors = {
    themeBackgroundDark: `245, 246, 250`, //rgb(245, 246, 250))
    themeBackgroundSemiDark: `255, 255, 255`, //rgb(255, 255, 255)
    themeShadow: `0, 0, 0`, //rgba(0, 0, 0, 0.493)
    themeBlue: `31,142,241`, //rgb(31, 142, 241)
    themeFont: `0, 0, 0`, //rgb(0, 0, 0)
    themeMegenta: `208,72,182`, //rgb(208, 72, 182)
    themeGreen: `0, 214, 132`, //rgb(0, 214, 132)
} */

export const DarkColors = {
    themeBackgroundDark: `24, 25, 38`, //rgb(24, 25, 38)
    themeBackgroundSemiDark: `39, 41, 61`, //rgb(39, 41, 61)
    themeShadow: `0, 0, 0`, //rgb(0, 0, 0)
    themeBlue: `31,142,241`, //rgb(31, 142, 241)
    themeFont: `238, 238, 238`, //rgb(238, 238, 238)
    themeMegenta: `208,72,182`, //rgb(208, 72, 182)
    themeGreen: `0, 214, 132`, //rgb(0, 214, 132)
    themeGold: `255, 229, 92`, //rgb(255, 229, 92)
    themeRed: `192, 13, 13`, //rgb(192, 13, 13)
};

export const boxAround = {
    border: `1px solid rgba(${DarkColors.themeBlue},0.3)`,
    padding: "15px 20px",
    backgroundColor: `rgb(${DarkColors.themeBackgroundSemiDark})`,
    borderRadius: `10px`,
    boxShadow: `rgba(${DarkColors.themeShadow}, 25%) 0px 1px 15px 0px`,
};

// Table theme Global
createTheme(
    "solarized",
    {
        text: {
            primary: `rgb(${DarkColors.themeFont})`,
            secondary: `rgb(${DarkColors.themeBlue})`,
        },
        background: {
            default: `rgb(${DarkColors.themeBackgroundSemiDark})`,
        },
        context: {
            background: "rgb(203, 75, 22)",
            text: "rgb(255, 255, 255)",
        },
        divider: {
            default: `rgba(${DarkColors.themeGreen},0.1)`,
        },
        button: {
            default: `rgb(${DarkColors.themeMegenta})`,
            hover: "rgba(0,0,0,.08)",
            focus: "rgba(255, 255, 255, 0.12)",
            disabled: "rgba(255, 255, 255, 0.34)",
        },
        sortFocus: {
            default: "rgb(42, 161, 152)",
        },
    },
    "dark"
);
