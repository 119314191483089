import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {DarkColors, boxAround} from "../../../assets/styles/sharedCss";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import {useSelector} from "react-redux";
const {REACT_APP_API_URL} = process.env;

function RefundPayout(props) {
    const token = useSelector((state) => state.userResponse?.user?.token);
    const [toBeRefunded, setToBeRefunded] = useState([]);
    const [refundCompleted, setRefundCompleted] = useState([]);
    const [payoutUser, setPayoutUser] = useState({
        _id: "",
        upiId: "",
        phone_number: "",
        amount: 0,
        name: "",
    });
    const [error, setError] = useState("");

    const selectedEntry = (e) => {
        console.log(e.target.dataset);
        let {_id, upiid, amount, phone_number, name} = e.target.dataset;
        setPayoutUser({_id, upiId: upiid, amount, phone_number, name: name});
    };
    useEffect(() => {
        axios({
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            url: REACT_APP_API_URL + "/adminPanel/getRefundUsers",
        }).then((res) => {
            let response = res.data;
            if (response.status === "failure") {
                setError(response.message);
            } else if (response.status === "success") {
                let users = response.message;
                let toBeRefundeda = [];
                let refundComplete = [];
                users.map((user) => {
                    if (user.status === "To be refunded") {
                        toBeRefundeda.push(user);
                    } else if (user.status === "Refund Completed") {
                        refundComplete.push(user);
                    }
                });
                setToBeRefunded(toBeRefundeda);
                setRefundCompleted(refundComplete);
                setError("");
            }
        });
    }, []);
    const onSubmit = async (e) => {
        axios({
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },
            url: REACT_APP_API_URL + "/adminPanel/payoutUser",
            data: JSON.stringify(payoutUser),
        }).then((res) => {
            let response = res.data;
            if (response.status === "failure") {
                setError(response.message);
            } else if (response.status === "success") {
                // let id = response.message._id;
                transferUser(response.message);
            }
        });
    };

    const transferUser = (data) => {
        if (data.manual) {
            setRefundCompleted([{...data.user}, ...refundCompleted]);
        } else {
            const user = toBeRefunded.find((user) => user._id === data.user._id);
            setToBeRefunded([...toBeRefunded.filter((user) => user._id !== data.user._id)]);
            setRefundCompleted([{...data.user}, ...refundCompleted]);
        }
        setPayoutUser({_id: "", upiId: "", phone_number: "", amount: 0});
        setError("");
    };
    return (
        <Container>
            <h1>Payout to user -</h1>
            <PayoutContainer>
                <input type="text" value={payoutUser.upiId} placeholder="Enter Upi ID" onChange={(e) => setPayoutUser({...payoutUser, upiId: e.target.value})} />
                <input type="text" value={payoutUser.phone_number} placeholder="Enter phone number" onChange={(e) => setPayoutUser({...payoutUser, phone_number: e.target.value})} />
                <input type="number" value={payoutUser.amount} placeholder="Enter Amount" onChange={(e) => setPayoutUser({...payoutUser, amount: e.target.value})} />
                <button onClick={onSubmit}>Send Money</button>
                {error}
            </PayoutContainer>
            <RefundData>
                <ToBeRefunded style={boxAround}>
                    <h2>To be refunded</h2>
                    {toBeRefunded.map((user) => {
                        return (
                            <React.Fragment key={user._id}>
                                <div className="toBeRefundedUsers">
                                    <span>
                                        {user.phone_number} | {user.upiId} | {user.amount} | {new Date(user.txnDate).toDateString()}
                                    </span>
                                    <button data-_id={user._id} data-name={user.name} data-amount={user.amount} data-phone_number={user.phone_number} data-upiid={user.upiId} onClick={selectedEntry}>
                                        Pay me
                                    </button>
                                </div>
                                <hr />
                            </React.Fragment>
                        );
                    })}
                </ToBeRefunded>
                <RefundedUsers style={boxAround}>
                    <h2>Refund Complete</h2>
                    {refundCompleted.map((user) => {
                        return (
                            <React.Fragment key={user._id}>
                                <div className="refundCompletedUsers">
                                    <span>
                                        {user.phone_number} | {user.upiId} | Rs.{user.amount} | {new Date(user.refundDate).toDateString()}
                                    </span>
                                </div>
                                <hr />
                            </React.Fragment>
                        );
                    })}
                </RefundedUsers>
            </RefundData>
        </Container>
    );
}

export default RefundPayout;

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const PayoutContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    input {
        border-radius: 5px;
        border: 0;
        padding: 6px 10px;
        width: 300px;
    }
    button {
        width: 200px;
        padding: 6px 10px;
    }
`;
const RefundData = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 69px;
`;

const ToBeRefunded = styled.ul`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 400px;
    div {
        padding: 10px;
        :hover {
        }
    }
    .toBeRefundedUsers {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
`;
const RefundedUsers = styled.ul`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 400px;
    div {
        padding: 10px;
        :hover {
        }
    }
    .refundCompletedUsers {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
`;
