import React, { useEffect, useRef, useState } from "react";
import { DarkColors, boxAround } from "../../../assets/styles/sharedCss";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import axios from "axios";
import ClipLoader from "react-spinners/RotateLoader";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import moment from "moment";
const { REACT_APP_API_URL } = process.env;

function GoldDeletedAccounts() {
    const accessToken = useSelector((state) => state.userResponse?.user?.token);
    const [computedData, setComputedData] = useState({ total: 0, data: {} });
    const [deletedLogs, setDeletedLogs] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        fetchDeletedAccountLogs();
    }, []);

    useEffect(() => {
        console.log("aa", endDate);
        computeLogs();
    }, [endDate]);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    async function fetchDeletedAccountLogs() {
        setLoading(true);
        await axios({
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: accessToken,
            },
            url: REACT_APP_API_URL + "/adminPanel/fetchFromS3",
            data: { file: "goldPeDeletedAccounts", fetchData: ["Today"] },
        }).then((res) => {
            let response = res.data;
            if (response.status === "success") {
                setDeletedLogs(response.message);
                computeLogs(response.message);
            } else if (response.status === "failure") {
            }
        });
    }
    const computeLogs = async (logs) => {
        console.log("Pizza", startDate, endDate);
        // console.log(moment(startDate));
        console.log("Compute");
        var data = { total: 0, data: {} };
        var logObj = JSON.parse(deletedLogs || logs)["Today"];
        var logsArray = logObj.split(/\r?\n/);
        logsArray.pop();
        let s = moment(startDate).unix();
        let e = moment(endDate);
        console.log(endDate, startDate);
        console.log(logsArray.length);
        for (let day in logsArray) {
            var log = JSON.parse(logsArray[day]);
            if (startDate && endDate) {
                if (
                    !(
                        moment(moment(log.timestamp, "d/M/YYYY, h:mm:s a")._i).unix() > moment(startDate).unix() &&
                        moment(moment(log.timestamp, "d/M/YYYY, h:mm:s a")._i).unix() < moment(endDate).unix()
                    )
                ) {
                    console.log(
                        moment(moment(log.timestamp, "d/M/YYYY, h:mm:s a")._i).toISOString(),
                        moment(startDate).unix(),
                        moment(moment(log.timestamp, "d/M/YYYY, h:mm:s a")._i).toLocaleString(),
                        moment(endDate).unix()
                    );
                    continue;
                } else {
                    // console.log(log.timestamp, moment(log.timestamp, "d/M/YYYY, h:mm:s a") > moment(startDate), "==", moment(log.timestamp), moment(endDate).unix());
                }
            }
            for (let reason of log.questionnaire.reasons) {
                if (data.data[reason]) {
                    data.data[reason]++;
                } else {
                    data.data[reason] = 1;
                }
            }
            data.total++;
        }
        setComputedData(data);
        setLoading(false);
    };
    return (
        <ApiLogsWrapper>
            <Container>
                {loading ? (
                    <ClipLoader color={`rgb(${DarkColors.themeGold})`} loading={loading} size={20} />
                ) : (
                    <>
                        <Button onClick={fetchDeletedAccountLogs}>Fetch Again</Button>
                        <PickDate>
                            <span>Pick a date : </span>
                            {/* <DatePicker dateFormat="MMM/dd/yy" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                            <DatePicker
                                className="DDAA"
                                selectsRange={true}
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    onChange(update);
                                }}
                                isClearable
                            />
                        </PickDate>
                        <span>Number of users : {computedData.total}</span>
                        <Questions>
                            {Object.keys(computedData.data).map((key) => {
                                return (
                                    <Question style={boxAround} key={key}>
                                        <div>{key}</div>
                                        <div>:</div>
                                        <div>{computedData.data[key]}</div>
                                    </Question>
                                );
                            })}
                        </Questions>
                    </>
                )}
            </Container>
        </ApiLogsWrapper>
    );
}

export default GoldDeletedAccounts;

const ApiLogsWrapper = styled.div`
    flex: 1;
`;

const Container = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Button = styled.button`
    width: fit-content;
    padding: 5px 10px;
`;
const Question = styled.div`
    display: flex;
    flex-direction: row;
    width: fit-content;
    gap: 10px;
    align-items: center;
    padding: 5px 10px;
`;
const Questions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
const PickDate = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    input {
        margin-right: 50px;
        margin-top: 5px;
        width: auto;
        border: 0;
        :focus {
            outline: 0;
        }
        :active {
            z-index: -1;
        }
    }
    button {
        margin-left: 20px;
    }
`;
