import React from "react";
import { useState } from "react";
import { DarkColors, boxAround } from "../../../assets/styles/sharedCss";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import { useRef } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import Category from "../../../data/Category";
import moment from "moment";

const { REACT_APP_API_URL } = process.env;
const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
function SearchGoldUser() {
    const token = useSelector((state) => state.userResponse?.user?.token);
    var tables = 1;
    const [userDetails, setUserDetails] = useState({});
    const [error, setError] = useState("");
    const searchData = useRef("");
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [dateError, setDateError] = useState("");
    const [ledgerLogFilter, setLedgerLogFilter] = useState({ transactionType: undefined, transactionMode: undefined, paymentMode: undefined, status: undefined });
    const totalVARows = useRef(0);
    const totalWalletStatementRows = useRef(0);
    const conditionalRowStyles = {
        limits: [
            {
                when: (row) => row.limitStatus !== "ACTIVE",
                style: {
                    backgroundColor: `#b8000053`,
                    color: "white",
                },
            },
        ],
        ledgerTxns: [
            {
                when: (row) => row.status !== "APPROVED",
                style: {
                    backgroundColor: `#b8000053`,
                    color: "white",
                },
            },
        ],
        vaStatement: [
            {
                when: (row) => row.moneyTransferStatus !== "SUCCESS",
                style: {
                    backgroundColor: `#b8000053`,
                    color: "white",
                },
            },
        ],
        walletTxns: [
            {
                when: (row) => row.status !== "SUCCESS",
                style: {
                    backgroundColor: `#b8000053`,
                    color: "white",
                },
            },
        ],
    };
    const onSubmit = async (e) => {
        e.preventDefault();
        setUserDetails({});
        if (searchData.current.value) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/getUsersDetails",
                params: { user: searchData.current.value },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        setUserDetails({ "User Details": data.message.user });
                        setLedgerLogFilter({ transactionType: undefined, txnMode: undefined, paymentMode: undefined, status: undefined });
                        setError("");
                    } else {
                        setError(data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Enter Phone number/Email id");
        }
    };

    const fetchCardDetails = async (e) => {
        if (searchData.current.value) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/getUsersCardDetails",
                params: { user: searchData.current.value },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        setUserDetails({ ...userDetails, "Card Details": data.message.card });
                        setError("");
                    } else {
                        setError("aaa");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchCreditsDebits = async (e) => {
        if (searchData.current.value) {
            if (startDate && endDate) {
                let from = moment(startDate).format("yyyy-MM-DD");
                let to = moment(endDate).format("yyyy-MM-DD");
                await axios({
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                    },
                    url: REACT_APP_API_URL + "/adminPanel/getUsersCrDb",
                    params: { user: searchData.current.value, from, to },
                })
                    .then((res) => {
                        var data = res.data;
                        if (data.status === "success") {
                            setUserDetails({ ...userDetails, "Credits & Debits": data.message });
                            setDateError("");
                        } else {
                            setError("aaa");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setDateError("Select dates.");
            }
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchLedgerTxns = async (e) => {
        if (searchData.current.value) {
            if (startDate && endDate) {
                let from = moment(startDate).format("yyyy-MM-DD");
                let to = moment(endDate).format("yyyy-MM-DD");
                await axios({
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                    },
                    url: REACT_APP_API_URL + "/adminPanel/getUsersLedgerTxns",
                    params: { user: searchData.current.value, from, to },
                })
                    .then((res) => {
                        var data = res.data;
                        if (data.status === "success") {
                            setUserDetails({ ...userDetails, Ledger: data.message });
                            setDateError("");
                            setLedgerLogFilter({ transactionType: undefined, txnMode: undefined, paymentMode: undefined, status: undefined });
                        } else {
                            setError("Error in fetching upi transactions.");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setDateError("Select dates.");
            }
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchWalletDetails = async (e) => {
        if (searchData.current.value) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/getUsersWalletDetails",
                params: { user: searchData.current.value },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        setUserDetails({ ...userDetails, "Wallet Details": data.message });
                    } else {
                        setError("aaa");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchAccountDetails = async (e) => {
        if (searchData.current.value) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/getUsersAccountDetails",
                params: { user: searchData.current.value },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        setUserDetails({ ...userDetails, "Account Details": data.message });
                    } else {
                        setError("Error in fetching Account Details");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchUserRewards = async (e) => {
        if (searchData.current.value) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/getUsersRewardStatus",
                params: { user: searchData.current.value },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        setUserDetails({ ...userDetails, "User Rewards": data.message });
                    } else {
                        setError("Error in fetching Account Details");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchVaDetails = async (e, page = 0) => {
        if (searchData.current.value) {
            if (startDate && endDate) {
                let from = moment(startDate).format("yyyy-MM-DD");
                let to = moment(endDate).format("yyyy-MM-DD");
                await axios({
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                    },
                    url: REACT_APP_API_URL + `/adminPanel/getUsersVAStatement`,
                    params: { user: searchData.current.value, from, to, page },
                })
                    .then((res) => {
                        var data = res.data;
                        if (data.status === "success") {
                            if (page === 0 && data.message.statement.length === 10) {
                                totalVARows.current = data.message.statement.length + 1;
                            } else if (data.message.statement.length === 10) {
                                totalVARows.current += data.message.statement.length;
                            } else {
                                totalVARows.current += data.message.statement.length;
                            }
                            setDateError("");
                            setUserDetails({ ...userDetails, "VA Statement": data.message });
                        } else {
                            setDateError(data.message);
                        }
                    })
                    .catch((err) => {
                        setDateError("Something Went Wrong");
                    });
            } else {
                setDateError("Select dates.");
            }
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchWalletTxns = async (e, page = 0) => {
        if (searchData.current.value) {
            if (startDate && endDate) {
                if (e !== "") {
                    delete userDetails["Wallet Statement"];
                    totalWalletStatementRows.current = 0;
                }
                let from = moment(startDate).format("yyyy-MM-DD");
                let to = moment(endDate).format("yyyy-MM-DD");
                await axios({
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                    },
                    url: REACT_APP_API_URL + "/adminPanel/getUsersTransactions",
                    params: { user: searchData.current.value, from, to, page },
                })
                    .then((res) => {
                        var data = res.data;
                        if (data.status === "success") {
                            if (page === 0 && data.message.statement.length === 10) {
                                totalWalletStatementRows.current = data.message.statement.length + 1;
                            } else if (data.message.statement.length === 10) {
                                totalWalletStatementRows.current += data.message.statement.length;
                            } else {
                                totalWalletStatementRows.current += data.message.statement.length;
                            }
                            setUserDetails({ ...userDetails, "Wallet Statement": data.message });
                            setDateError("");
                        } else {
                            setDateError(data.message);
                        }
                    })
                    .catch((err) => {
                        setDateError("Something Went Wrong");
                    });
            } else {
                setDateError("Select dates.");
            }
        } else {
            setError("Search input can not be empty");
        }
    };
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const handlePageChange = (page, apiCallType) => {
        if (apiCallType === "VA Statement") {
            fetchVaDetails("", page - 1);
        } else if (apiCallType === "Wallet Statement") {
            fetchWalletTxns("", page - 1);
        }
    };

    const getFilteredLog = (dataKey) => {
        let value = [...userDetails["Ledger"]["ledgerTxns"]];
        let filteredTableValue = value;
        Object.keys(ledgerLogFilter).map((key) => {
            if (ledgerLogFilter[key]) {
                let toPop = [];
                value.map((log) => {
                    if (log[key] !== ledgerLogFilter[key]) {
                        let index = value.indexOf(log);
                        toPop.push(index);
                    }
                });
                toPop.reverse();
                toPop.map((item) => {
                    filteredTableValue.splice(item, 1);
                });
            }
        });
        return getTable(filteredTableValue, dataKey);
    };

    const clearFilters = () => {
        document.getElementById("type").value = "Type";
        document.getElementById("txnMode").value = "Txn Mode";
        document.getElementById("paymentMode").value = "Payment Mode";
        document.getElementById("status").checked = false;
        setLedgerLogFilter({ transactionType: undefined, txnMode: undefined, paymentMode: undefined, status: undefined });
    };

    function getTable(cols, tableType) {
        tables++;
        const columns = [
            {
                name: "TimeStamp",
                selector: (row) => row.timestamp,
                grow: 2,
                sortable: true,
            },
            {
                name: "Type",
                selector: (row) => row.type,
                sortable: true,
            },
            {
                name: "Description",
                selector: (row) => row.description,
                sortable: true,
                grow: 2,
                wrap: true,
            },
        ];
        if (tableType === "VA Statement") {
            columns.push({ name: "Withdrawal Amount", selector: (row) => row.withdrawalAmount, sortable: true });
            columns.push({ name: "Deposit Amount", selector: (row) => row.depositAmount, sortable: true });
            columns.push({ name: "Transfer Type", selector: (row) => row.transferType, sortable: true });
            columns.push({ name: "Status", selector: (row) => row.moneyTransferStatus, sortable: true });
        } else if (tableType === "Wallet Statement") {
            columns.push({ name: "Amount", selector: (row) => row.amount, sortable: true });
            columns.push({ name: "Sender", selector: (row) => row.sender, sortable: true });
            columns.push({ name: "Recipient", selector: (row) => row.recipient, sortable: true });
            columns.push({ name: "Status", selector: (row) => row.status, sortable: true });
        } else if (tableType === "User Details") {
            columns.length = 0;
            columns.push({ name: "Category Name", selector: (row) => row.name, sortable: true });
            columns.push({ name: "Set Limits", selector: (row) => row.setLimit, sortable: true });
            columns.push({ name: "Available Limits", selector: (row) => row.availableLimit, sortable: true });
        } else if (tableType === "UPI Transactions") {
            columns.length = 0;
            columns.push({ name: "Category Name", selector: (row) => Category[row.categoryId], sortable: true });
            columns.push({ name: "Brand Name", selector: (row) => row.brandName, sortable: true });
            columns.push({ name: "UPI ID", selector: (row) => row.upiMid.upiId, sortable: true });
            columns.push({ name: "Amount", selector: (row) => row.transactionAmount, sortable: true });
            columns.push({ name: "Date", selector: (row) => row.date, sortable: true });
            columns.push({ name: "Status", selector: (row) => row.status, sortable: true });
        } else if (tableType === "User Rewards") {
            columns.length = 0;
            columns.push({ name: "Type", selector: (row) => row.typeOfCard, sortable: true });
            columns.push({ name: "Amount", selector: (row) => row.amount, sortable: true });
            columns.push({ name: "Status", selector: (row) => row.status, sortable: true });
            // columns.push({name: "Txn Amount", selector: (row) => row.txnId.transactionAmount, sortable: true});
        } else if (tableType === "Ledger") {
            columns.length = 0;
            columns.push({ name: "Type", selector: (row) => row.transactionType, sortable: true });
            columns.push({ name: "Brand Name", selector: (row) => row.brandName, sortable: true });
            columns.push({ name: "Amount", selector: (row) => row.transactionAmount, sortable: true });
            columns.push({ name: "Txn Mode", selector: (row) => row.transactionMode, sortable: true });
            columns.push({ name: "Payment Mode", selector: (row) => row.paymentMode, sortable: true });
            columns.push({ name: "Date", selector: (row) => row.date, sortable: true });
            columns.push({ name: "Status", selector: (row) => row.status, sortable: true });
            // columns.push({name: "Txn Amount", selector: (row) => row.txnId.transactionAmount, sortable: true});
        }
        cols.map((item, n) => {
            cols[n].name = Category[cols[n]["categoryId"]];
        });
        return (
            <>
                <DataTable
                    columns={columns}
                    // data={cols.filter((item) => {
                    //     if (filter === "") {
                    //         return item;
                    //     } else if (item.identifier.toLowerCase().includes(filter.toLowerCase())) {
                    //         return item;
                    //     }
                    // })}
                    data={cols}
                    pagination
                    paginationServer
                    paginationTotalRows={tableType === "VA Statement" ? totalVARows.current : tableType === "Wallet Statement" ? totalWalletStatementRows.current : cols.length}
                    onChangePage={(page) => handlePageChange(page, tableType)}
                    theme="solarized"
                    key={tables}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="300px"
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    conditionalRowStyles={
                        tableType === "Ledger"
                            ? conditionalRowStyles.ledgerTxns
                            : tableType === "User Details"
                            ? conditionalRowStyles.limits
                            : tableType === "VA Statement"
                            ? conditionalRowStyles.vaStatement
                            : tableType === "Wallet Statement"
                            ? conditionalRowStyles.walletTxns
                            : undefined
                    }
                    dense
                />
            </>
        );
    }
    return (
        <>
            <Container>
                <Search style={boxAround}>
                    <InputBar>
                        <FaIcons.FaSearch />
                        <input ref={searchData} type="text" placeholder="Email Id / Phone Number / User Id" />
                    </InputBar>
                    <button className="searchBtn" onClick={onSubmit}>
                        Search
                    </button>
                    <span>{error}</span>
                </Search>
                {Object.keys(userDetails).length > 0 && (
                    <UserDetails>
                        <FetchButtons>
                            {/* <VirtualCard data={"a"}/> */}
                            <button className="fetchBtn" onClick={fetchCardDetails}>
                                Card Details
                            </button>
                            {/* <button onClick={fetchVaDetails}>VA Settlements</button> */}
                            {/* <button onClick={fetchWalletTxns}>Wallet transactions</button> */}
                            <button className="fetchBtn" onClick={fetchWalletDetails}>
                                Wallet Details
                            </button>
                            <button className="fetchBtn" onClick={fetchAccountDetails}>
                                Account Details
                            </button>
                            <button className="fetchBtn" onClick={fetchUserRewards}>
                                User Rewards
                            </button>
                        </FetchButtons>
                        <FetchButtons>
                            <DateFilter>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    selectsRange={true}
                                    selected={startDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        onChange(update);
                                    }}
                                    isClearable
                                />
                            </DateFilter>
                            <span>{dateError}</span>
                            {/* <button onClick={fetchCardDetails}>Card Details</button> */}
                            <button className="fetchBtn" onClick={fetchVaDetails}>
                                VA Statement
                            </button>
                            <button className="fetchBtn" onClick={fetchWalletTxns}>
                                Wallet transactions
                            </button>
                            <button className="fetchBtn" onClick={fetchCreditsDebits}>
                                Credits & Debits
                            </button>
                            <button className="fetchBtn" onClick={fetchLedgerTxns}>
                                Fetch Transactions
                            </button>
                            {/* <button onClick={fetchWalletDetails}>Wallet Details</button> */}
                        </FetchButtons>
                        <Data>
                            {Object.entries(userDetails).map(([dataKey, val]) => {
                                return (
                                    <DataCard style={boxAround} key={dataKey}>
                                        <h1>{dataKey}</h1>
                                        <span>
                                            {Object.entries(val).map(([key, value]) => {
                                                return (
                                                    <div key={key}>
                                                        {key === "statement" || key === "limits" || key === "scratchCards" ? (
                                                            getTable(value, dataKey)
                                                        ) : key === "ledgerTxns" ? (
                                                            <>
                                                                <LedgerFilters>
                                                                    <select
                                                                        id="type"
                                                                        defaultValue={"Type"}
                                                                        onChange={(e) => setLedgerLogFilter({ ...ledgerLogFilter, transactionType: e.target.value })}
                                                                    >
                                                                        <option value="Type" disabled>
                                                                            Type
                                                                        </option>
                                                                        <option value="DEBIT">DEBIT</option>
                                                                        <option value="CREDIT">CREDIT</option>
                                                                    </select>
                                                                    <select
                                                                        id="txnMode"
                                                                        defaultValue={"Txn Mode"}
                                                                        onChange={(e) => setLedgerLogFilter({ ...ledgerLogFilter, transactionMode: e.target.value })}
                                                                    >
                                                                        <option value="Txn Mode" disabled>
                                                                            Txn Mode
                                                                        </option>
                                                                        <option value="Manual">Manual</option>
                                                                        <option value="ZB">ZB</option>
                                                                        <option value="User">User</option>
                                                                        <option value="Cashback">Cashback</option>
                                                                        <option value="Refund">Refund</option>
                                                                        <option value="Reversal">Reversal</option>
                                                                    </select>
                                                                    <select
                                                                        id="paymentMode"
                                                                        defaultValue={"Payment Mode"}
                                                                        onChange={(e) => setLedgerLogFilter({ ...ledgerLogFilter, paymentMode: e.target.value })}
                                                                    >
                                                                        <option value="Payment Mode" disabled>
                                                                            Payment Mode
                                                                        </option>
                                                                        <option value="UPI">UPI</option>
                                                                        <option value="Card">Card</option>
                                                                        <option value="Cash">Cash</option>
                                                                    </select>
                                                                    <div id="failedTxns">
                                                                        <span>Show Failed Txn</span>
                                                                        <input
                                                                            type="checkbox"
                                                                            id="status"
                                                                            onClick={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setLedgerLogFilter({ ...ledgerLogFilter, status: "FAILED" });
                                                                                } else {
                                                                                    setLedgerLogFilter({ ...ledgerLogFilter, status: undefined });
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <button onClick={(e) => clearFilters()}>Clear Filters</button>
                                                                </LedgerFilters>
                                                                {getFilteredLog(dataKey)}
                                                            </>
                                                        ) : (
                                                            <h3>
                                                                {key} : {JSON.stringify(value)}
                                                            </h3>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </span>
                                    </DataCard>
                                );
                            })}
                        </Data>
                    </UserDetails>
                )}
            </Container>
        </>
    );
}

export default SearchGoldUser;

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Search = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4rem;
    align-items: center;
    /* justify-content: flex-start; */
    .searchBtn {
        font-size: 1.3rem;
        background-color: rgb(${DarkColors.themeBlue});
        padding: 8px 15px;
        color: rgb(${DarkColors.themeFont});
        border: 0;
        border-radius: 10px;
        :hover {
            cursor: pointer;
            background-color: rgba(${DarkColors.themeBlue}, 0.8);
        }
    }
`;

const InputBar = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 20px;
    input {
        width: 400px;
        font-size: 1.3rem;
        border: none;
        border-radius: 10px;
        padding: 8px 50px;
        :focus {
            outline: none;
        }
    }
    svg {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: rgb(${DarkColors.themeBackgroundSemiDark});
    }
    button {
        font-size: 1.3rem;
        background-color: rgb(${DarkColors.themeFont});
        padding: 6px 13px;
        color: rgb(${DarkColors.themeBackgroundSemiDark});
        border: 0;
        border-radius: 8px;
        :hover {
            cursor: pointer;
            background-color: rgba(${DarkColors.themeFont}, 0.8);
        }
    }
`;

const Data = styled.div`
    /* width: 1500px; */
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const DataCard = styled.div`
    overflow-x: auto;
`;
const LedgerFilters = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    #failedTxns {
        border: 1px solid white;
        padding: 0 5px;
    }
`;
const FetchButtons = styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .fetchBtn {
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(${DarkColors.themeFont}, 0.8);
        border-radius: 8px;
        border-color: rgba(${DarkColors.themeFont}, 0.8);
        background-color: rgba(${DarkColors.themeBackgroundSemiDark}, 0.5);
        :hover {
            border-color: rgba(${DarkColors.themeFont}, 1op);
            color: rgba(${DarkColors.themeFont}, 1);
            background-color: rgba(${DarkColors.themeBackgroundSemiDark}, 1);
            cursor: pointer;
        }
    }
`;
const UserDetails = styled.div`
    flex: 1;
    gap: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const DateFilter = styled.div`
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: fit-content;
    input {
        margin-right: 50px;
        margin-top: 5px;
        width: auto;
        border: 0;
        :focus {
            outline: 0;
        }
    }
    button {
        margin-left: 20px;
    }
`;
