import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {DarkColors} from "../../../assets/styles/sharedCss";
import {Outlet, useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";

const Utility = ({item}) => {
    const navigate = useNavigate();
    const cookie = new Cookies();
    const accessToken = cookie.get("auth");

    return (
        <UtilityWrapper>
            <Outlet />
        </UtilityWrapper>
    );
};

export default Utility;
const UtilityWrapper = styled.div`
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

`;
