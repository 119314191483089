import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import zbLogo from "../../../assets/images/ZeroBalance-logo.png";
import Cookies from "universal-cookie";
import { store } from "../../../redux/store";
import { LOG_IN, USER_LOGOUT } from "../../../redux/actions";
import { DarkColors } from "../../../assets/styles/sharedCss";
import { NavbarData } from "./NavbarData";
import NavbarMenu from "./NavbarMenu";
import { useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { socketConnect, socket } from "../../../utils/socketIo";

function Navbar() {
    const roles = useSelector((state) => state.userResponse?.user?.roles);
    const user = useSelector((state) => state.userResponse?.user);
    const navigate = useNavigate();
    const [hamburger, setHamburger] = useState(false);
    const cookie = new Cookies();
    useEffect(() => {
        if (user && user?.token) {
            // socketConnect(user.token);
        }
    }, []);

    // useEffect(() => {
    //     socket.on("latest-gold-rates", async (message) => {
    //         console.log(message);
    //     });
    //     socket.on("buy-gold-callback", async (message) => {
    //         console.log(message);
    //     });
    // }, []);
    const [pathname, setPathname] = useState();

    const logoutHandler = () => {
        cookie.remove("auth");
        store.dispatch({ type: USER_LOGOUT });
        navigate("/");
    };

    const handleHamburger = () => {
        setHamburger(hamburger ? !hamburger : !hamburger);
    };

    const NavMenu2 = (className) => {
        return (
            <>
                <NavbarHeader className={className}>
                    {roles.length === 0 ? (
                        "Contact admin to assing you some roles"
                    ) : (
                        <NavMenu>
                            {NavbarData.map((item, index) => {
                                return <NavbarMenu item={item} key={index} />;
                            })}
                        </NavMenu>
                    )}
                </NavbarHeader>
                <NavFooter className={className}>
                    <Link className="logoutItem" to="/" onClick={logoutHandler}>
                        <MdLogout className="icons" />
                        Logout
                    </Link>
                </NavFooter>
            </>
        );
    };

    return (
        <NavWrapper>
            <Nav id="nav">
                <ZBHeading>
                    <span>zero</span>
                    balance
                </ZBHeading>
                <Hamburger className="hamburger" onClick={handleHamburger}>
                    <GiHamburgerMenu size={25} />
                </Hamburger>
                <hr />
                {NavMenu2()}
                {hamburger && (
                    <HamburgerMenu>
                        <div className="cross">
                            <ImCross size={35} onClick={handleHamburger} />
                        </div>
                        {NavMenu2("hamburgerNav")}
                    </HamburgerMenu>
                )}
            </Nav>
            <Outlet />
        </NavWrapper>
    );
}

export default Navbar;

const ZBHeading = styled.div`
    text-align: center;
    padding: 15px 0;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 0;
    span {
        margin-right: 5px;
        background-color: #ffffff;
        padding: 0 5px;
        color: rgb(6, 97, 185);
        font-weight: 700;
    }
`;

const HamburgerMenu = styled.div`
    background-image: linear-gradient(rgb(6, 97, 185) 20%, rgb(4, 23, 100));
    z-index: 10000000;
    color: black;
    position: absolute;
    height: 100vh;
    inset: 0;
    display: flex;
    flex-direction: column;
    .cross {
        right: 10px;
        top: 10px;
        color: #ffffff;
    }
    div {
        text-align: right;
        padding: 10px;
        svg {
        }
    }
`;

const Hamburger = styled.div`
    display: none;
    @media only screen and (max-width: 1000px) {
        display: block;
    }
`;
const NavWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: rgb(${DarkColors.themeBackgroundDark});
    color: white;
    @media only screen and (max-width: 1000px) {
        flex-direction: column;
        width: 100vw;
        height: 100vh;
    }
`;
const Nav = styled.nav`
    position: sticky;
    width: 250px;
    background-image: linear-gradient(rgb(6, 97, 185) 20%, rgb(4, 23, 100));
    box-shadow: rgba(${DarkColors.themeBackgroundSemiDark}, 0.486) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    padding: 30px 10px;
    div {
        .logoutItem {
            align-self: flex-end;
            color: pink;
        }
    }
    hr {
        border: 1px rgb(${DarkColors.themeGreen}) solid;
    }
    @media only screen and (max-width: 1000px) {
        flex-direction: row;
        width: calc(100vw - 20px);
        height: 45px;
        padding: 5px 10px;
        justify-content: space-between;
        align-items: center;
        hr {
            display: none;
        }
    }
`;
const NavbarHeader = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    overflow: hidden;
    margin-top: 1;

    @media only screen and (max-width: 1000px) {
        /* display: none; */
        &:not(.hamburgerNav) {
            display: none;
        }

        /* .hamburgerNav {
        } */
    }
`;
const NavMenu = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
        width: 16px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(${DarkColors.themeFont});
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
`;
const NavFooter = styled.div`
    border-radius: 8px;
    background-color: rgba(${DarkColors.themeBlue}, 0.5);
    display: flex;
    flex-direction: row;
    box-shadow: rgba(${DarkColors.themeBlue}, 0.2) 0px 1px 15px 0px;
    transition: 0.1s;
    /* justify-content: space-around; */
    cursor: pointer;
    :hover {
        background-color: rgba(192, 13, 13, 0.966);
    }
    a {
        font-size: 18px;
        padding: 12px;
        flex: 1;
        margin-right: 0;
        text-decoration: none;
        color: rgb(${DarkColors.themeFont});
        :visited {
            color: rgb(${DarkColors.themeFont});
        }
    }
    @media only screen and (max-width: 1000px) {
        &:not(.hamburgerNav) {
            display: none;
        }
        hr {
            display: none;
        }
    }
`;
const Img = styled.img`
    width: 100%;
    @media only screen and (max-width: 1000px) {
        height: 50%;
        width: 50%;
    }
`;
