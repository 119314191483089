import React from "react";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {DarkColors, boxAround} from "../assets/styles/sharedCss";

function NotFound404() {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);
    return (
        <NotFoundPage>
            <h1>ded page 404</h1>
            <div className="flexGrow">
                <button onClick={goBack}>Go Back</button>
            </div>
        </NotFoundPage>
        
    );
}

export default NotFound404;


const NotFoundPage = styled.div`
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: center;
    justify-content: center;
    background-color: rgb(${DarkColors.themeBackgroundSemiDark});
    height: 100vh;
    color: rgb(${DarkColors.themeFont});
    div{
        button{
            padding:8px 14px;
            background-color: rgb(${DarkColors.themeBlue});
            outline: 0;
            border: 0;
            border-radius: 4px;
            font-weight: bold;
            :hover{
                cursor: pointer;
            }
        }
    }
`