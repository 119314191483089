import React, {useState} from "react";
import styled from "styled-components";
import {DarkColors} from "../../../assets/styles/sharedCss";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import Cookies from "universal-cookie";
const {REACT_APP_API_URL} = process.env;

const SearchUser = ({item}) => {
    const cookie = new Cookies();
    const accessToken = cookie.get("auth");
    const [searchData, setSearchData] = useState("");
    const [searchDataError, setSearchDataError] = useState();
    const [userData, setUserData] = useState();

    const onSubmit = async (e) => {
        e.preventDefault();
        setSearchDataError();
        setUserData();
        if (searchData) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: accessToken,
                },
                url: REACT_APP_API_URL + "/adminPanel/getSingleUser",
                params: {user: searchData.trim()},
            }).then((res) => {
                var data = res.data;
                if (data.status === "success") {
                    setUserData(data.message[0]);
                } else {
                    setSearchDataError(data.message);
                }
            });
        } else {
            setSearchDataError("Enter some value");
        }
    };

    return (
        <>
            <Container>
                <Search>
                    <InputBar>
                        <FaIcons.FaSearch />
                        <input
                            value={searchData}
                            onChange={(e) => setSearchData(e.target.value)}
                            type="text"
                            placeholder="User Id/Email/Phone Number/Account Number"
                        />
                        <button>Locked Accounts</button>
                        <select defaultValue={'default'}>
                            <option value="default" disabled>
                                Kyc Status
                            </option>
                            <option>New User</option>
                            <option>Budget Accepted</option>
                            <option>Onboarded with ZB</option>
                            <option>Card Issued</option>
                            <option>User Registered</option>
                        </select>
                        <button>Admins</button>
                    </InputBar>
                    <button className="searchBtn" onClick={onSubmit}>
                        Search
                    </button>
                </Search>
                <Data>
                    {userData &&
                        Object.entries(userData).map(([key, value]) => {
                            return (
                                <>
                                    <div key={key}>
                                        <h3>
                                            {key} : {JSON.stringify(value)}
                                        </h3>
                                    </div>
                                </>
                            );
                        })}
                </Data>
            </Container>
        </>
    );
};

export default SearchUser;

const Container = styled.div`
    padding: 15px 20px;
    background-color: rgb(${DarkColors.themeBackgroundSemiDark});
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 50vh;
    gap: 20px;
`;

const Search = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    gap: 4rem;
    justify-content: space-between;
    input {
        flex: 1;
        font-size: 1.3rem;
        border: none;
        border-radius: 10px;
        padding: 8px 50px;
        :focus {
            outline: none;
        }
    }
    svg {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    .searchBtn {
        font-size: 1.3rem;
        background-color: rgb(${DarkColors.themeBlue});
        padding: 8px 15px;
        color: rgb(${DarkColors.themeFont});
        border: 0;
        border-radius: 10px;
        :hover {
            cursor: pointer;
            background-color: rgba(${DarkColors.themeBlue}, 0.8);
        }
    }
`;

const InputBar = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 20px;
    svg {
        color: rgb(${DarkColors.themeBackgroundSemiDark});
    }
    button {
        font-size: 1.3rem;
        background-color: rgb(${DarkColors.themeFont});
        padding: 6px 13px;
        color: rgb(${DarkColors.themeBackgroundSemiDark});
        border: 0;
        border-radius: 8px;
        :hover {
            cursor: pointer;
            background-color: rgba(${DarkColors.themeFont}, 0.8);
        }
    }
`;
const Data = styled.div`
    overflow-y: auto;
    overflow-x: auto;
`;
