import React from "react";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import "../assets/styles/index.css";
import AccessLogs from "./App/Logs/AccessLogs";
import ApiLogs from "./App/Logs/ApiLogs";
import LogsView from "./App/Logs/LogsView";
import Navbar from "./App/Navbar/Navbar";
import Login from "./Login";
import SearchUser from "./App/Utilities/SearchUser";
import Utility from "./App/Utilities/Utility";
import RequireAuth from "./RequireAuth";
import Unauthorized from "./Unauthorized";
import SearchSpacUser from "./App/Support/SearchSpacUser";
import NotFound404 from "./NotFound404";
import RefundPayout from "./App/Support/RefundPayout";
import PreAuthPair from "./App/Logs/PreAuthPair";
import GoldApiLogs from "./App/Logs/GoldApiLogs";
import GoldDeletedAccounts from "./App/Logs/GoldDeletedAccounts";
import SearchGoldUser from "./App/Support/SearchGoldUser";
import Home from "./App/Home/Home";
const { REACT_APP_ROLES } = process.env;

const ROLES = JSON.parse(REACT_APP_ROLES);

function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="unauthorized" element={<Unauthorized />} />
                    <Route path="*" element={<NotFound404 />} />
                    <Route path="/app" element={<Navbar />}>
                        <Route path="" element={<Home />} />

                        <Route element={<RequireAuth allowedRoles={[parseInt(ROLES.Admin), parseInt(ROLES.Analytics)]} />}>
                            <Route path="logs" element={<LogsView />}>
                                <Route path="access" element={<AccessLogs />} />
                                <Route path="api" element={<ApiLogs />} />
                                <Route path="preauthpaircheck" element={<PreAuthPair />} />
                                <Route path="gold" element={<GoldApiLogs />} />
                            </Route>
                        </Route>
                        <Route element={<RequireAuth allowedRoles={[parseInt(ROLES.Admin), parseInt(ROLES.Support), parseInt(ROLES.PayoutAdmin)]} />}>
                            <Route path="support" element={<Utility />}>
                                <Route path="getspacuser" element={<SearchSpacUser />} />
                                <Route path="getgolduser" element={<SearchGoldUser />} />
                                <Route path="deletedaccounts" element={<GoldDeletedAccounts />} />
                                {/* <Route element={<RequireAuth allowedRoles={[parseInt(ROLES.PayoutAdmin)]} />}>
                                    <Route path="refundUser" element={<RefundPayout />} />
                                </Route> */}
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </Router>
        </>
    );
}

export default App;
