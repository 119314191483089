import React, { useRef, useState } from "react";
import axios from "axios";
import styled, { withTheme } from "styled-components";
import { DarkColors, boxAround } from "../../../assets/styles/sharedCss";
import DatePicker from "react-datepicker";
import Cookies from "universal-cookie";
import ErrorLogs from "./ErrorLogs";
import { Outlet, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import ClipLoader from "react-spinners/SyncLoader";
import { useDispatch, useSelector } from "react-redux";
import { ACCESS_LOGS, API_LOGS, API_LOGS_PREAUTH, RESPONSE_TIME_LOGS, GOLD_API_LOGS } from "../../../redux/actions";
const { REACT_APP_API_URL } = process.env;

function LogsView() {
    const accessToken = useSelector((state) => state.userResponse?.user?.token);
    const [logsLoading, sestLogsLoading] = useState(false);
    const pathname = useOutletContext();
    const dispatch = useDispatch();
    const [todayDate, setTodayDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState("");
    // const error = useRef("");
    const [showOnlyError, setShowOnlyError] = useState();
    const [logsData, setLogsData] = useState(undefined);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const onSubmit = async (e) => {
        sestLogsLoading(true);
        e.preventDefault();
        let fetchData = [];
        let path = window.location.pathname.split("/").at(-1);
        let file;
        // file = path === "preauthpaircheck" ? "api" : path === "responsetime" ? "responseTime" : path;
        if (path === "preauthpaircheck") {
            file = "api";
        } else if (path === "responsetime") {
            file = "responseTime";
        } else if (path === "gold") {
            file = "goldApi";
        } else {
            file = path;
        }
        if (!startDate) return setError("Select a date");
        if (startDate.getDate() === todayDate.getDate()) {
            fetchData.push("Today");
        } else if (startDate && endDate) {
            var diffDays = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24), 10) + 1;
            if (diffDays > 7) {
                return setError("You can only select max of 7 days");
            }
            let from = new Date(startDate);
            let till = new Date(endDate);
            while (from <= till && from <= todayDate) {
                from.setDate(from.getDate() + 1);
                let date = from.toString().split(" ");
                let fileName = date[1] + " " + date[2] + " " + date[3] + "/" + file + ".log";
                if (from.getDate() - 1 === todayDate.getDate()) {
                    fetchData.push("Today");
                } else {
                    fetchData.push(fileName);
                }
            }
        } else if (startDate < todayDate) {
            startDate.setDate(startDate.getDate() + 1);
            let date = startDate.toString().split(" ");
            let fileName = date[1] + " " + date[2] + " " + date[3] + "/" + file + ".log";
            fetchData.push(fileName);
        } else {
            return setError("Select valid date.");
        }
        await axios({
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: accessToken,
            },
            url: REACT_APP_API_URL + "/adminPanel/fetchFromS3",
            data: { file, fetchData },
        }).then((res) => {
            let response = res.data;
            if (response.status === "success") {
                sestLogsLoading(false);
                setError("");
                let data = response.message;
                if (path === "access") {
                    dispatch({ type: ACCESS_LOGS, payload: { data } });
                } else if (path === "api") {
                    dispatch({ type: API_LOGS, payload: { data } });
                } else if (path === "preauthpaircheck") {
                    dispatch({ type: API_LOGS_PREAUTH, payload: { data } });
                } else if (path === "responsetime") {
                    dispatch({ type: RESPONSE_TIME_LOGS, payload: { data } });
                } else if (path === "gold") {
                    dispatch({ type: GOLD_API_LOGS, payload: { data } });
                }
            } else if (response.status === "failure") {
                setError(response.message);
            }
        });
        // setError("")
    };
    return (
        <LogsWrapper>
            <Container>
                <Filters style={boxAround}>
                    <PickDate>
                        <span>Pick a date : </span>
                        {/* <DatePicker dateFormat="MMM/dd/yy" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                        <DatePicker
                            className="DDAA"
                            selectsRange={true}
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                onChange(update);
                            }}
                            isClearable
                        />
                    </PickDate>
                    {/* <div>
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    setShowOnlyError(e.target.checked);
                                }}
                            />
                        </div> */}
                    <div>
                        <button onClick={onSubmit}>Fetch</button>
                    </div>
                    <div>
                        <span>{error}</span>
                    </div>
                </Filters>
                {logsLoading ? (
                    <Loader>
                        <ClipLoader color={`rgb(${DarkColors.themeGold})`} loading={logsLoading} size={20} />
                    </Loader>
                ) : (
                    <Outlet />
                )}
            </Container>
        </LogsWrapper>
    );
}

export default LogsView;

const LogsWrapper = styled.div`
    overflow: auto;
    flex: 1;
    overflow-y: scroll;
`;
const Container = styled.div`
    padding: 40px;
    @media only screen and (max-width: 1000px) {
        padding: 15px;
    }
`;
const Filters = styled.div`
    /* padding: 15px 20px;
    background-color: rgb(${DarkColors.themeBackgroundSemiDark});
    border-radius: 10px;
    box-shadow: rgba(${DarkColors.themeBackgroundSemiDark}, 0.24) 0px 3px 8px; */
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
`;
const PickDate = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    input {
        margin-right: 50px;
        margin-top: 5px;
        width: auto;
        border: 0;
        :focus {
            outline: 0;
        }
        :active {
            z-index: -1;
        }
    }
    button {
        margin-left: 20px;
    }
`;
const Loader = styled.div`
    margin: 20px;
`;
