import React from "react";
import { useState } from "react";
import { DarkColors, boxAround } from "../../../assets/styles/sharedCss";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import axios from "axios";
import { useRef } from "react";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { GOLD_SEARCHED_USER } from "../../../redux/actions";
import moment from "moment";
const { REACT_APP_API_URL } = process.env;
const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;
function SearchGoldUser() {
    const dispatch = useDispatch();
    const token = useSelector((state) => state.userResponse?.user?.token);
    const searchedUser = useSelector((state) => state.userResponse?.goldSearchedUser) ?? {};
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [filters, setFilters] = useState({
        Tickets: { status: undefined, mode: undefined },
        Ledger: { type: undefined, status: undefined },
        Referrals: { type: undefined, status: undefined },
    });
    const [dateError, setDateError] = useState("");
    const [error, setError] = useState("");
    const searchData = useRef("");
    var tables = 1;
    const conditionalRowStyles = {
        tickets: [
            {
                when: (row) => row.status === "Expired",
                style: {
                    backgroundColor: `#b8000053`,
                    color: "white",
                },
            },
            {
                when: (row) => row.status === "Inactive",
                style: {
                    backgroundColor: `#ced10c52`,
                    color: "white",
                },
            },
        ],
        ledger: [
            {
                when: (row) => row.status === "failure",
                style: {
                    backgroundColor: `#b8000053`,
                    color: "white",
                },
            },
            {
                when: (row) => row.status === "pending",
                style: {
                    backgroundColor: `#ced10c52`,
                    color: "white",
                },
            },
        ],
    };
    const onClear = async (e) => {
        searchData.current.value = "";
        dispatch({ type: GOLD_SEARCHED_USER, payload: {} });
    };
    const clearFilters = (dataKey) => {
        if (dataKey === "Tickets") {
            document.getElementById("mode").value = "mode";
            document.getElementById("status").value = "status";
            setFilters({ ...filters, Tickets: { status: undefined, mode: undefined } });
        } else if (dataKey === "Ledger") {
            document.getElementById("type").value = "type";
            document.getElementById("ledgerStatus").value = "status";
            setFilters({ ...filters, Ledger: { type: undefined, status: undefined } });
        }
    };
    const onSubmit = async (e) => {
        e.preventDefault();
        if (searchData.current.value) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/gold/getUsersDetails",
                params: { user: searchData.current.value },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        if (data.message) {
                            setError("");
                            dispatch({ type: GOLD_SEARCHED_USER, payload: { User: data.message } });
                        } else {
                            setError("No user found");
                        }
                    } else {
                        setError(data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Enter Phone number/Email id");
        }
    };
    const fetchLockerDetails = async (e) => {
        if (searchedUser.User["_id"]) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/gold/getUserLocker",
                params: { user: searchedUser.User["_id"] },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        dispatch({ type: GOLD_SEARCHED_USER, payload: { ...searchedUser, "User Locker": data.message } });
                        setError("");
                    } else {
                        setError("Error in fetching locker data");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchUserSummary = async (e) => {
        if (searchedUser.User["_id"]) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/gold/getUserSummary",
                params: { user: searchedUser.User["_id"] },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        dispatch({ type: GOLD_SEARCHED_USER, payload: { ...searchedUser, "User Summary": data.message } });
                        setError("");
                    } else {
                        setError("Error in fetching locker data");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchUserTickets = async (e) => {
        if (searchedUser.User["_id"]) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/gold/getUserTickets",
                params: { user: searchedUser.User["_id"] },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        dispatch({ type: GOLD_SEARCHED_USER, payload: { ...searchedUser, Tickets: data.message } });
                        setError("");
                        clearFilters("Tickets");
                    } else {
                        setError("Error in fetching user tickets");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchUserReferrals = async (e) => {
        if (searchedUser.User["_id"]) {
            await axios({
                method: "get",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    token: token,
                },
                url: REACT_APP_API_URL + "/adminPanel/gold/getUserReferrals",
                params: { user: searchedUser.User["_id"] },
            })
                .then((res) => {
                    var data = res.data;
                    if (data.status === "success") {
                        dispatch({ type: GOLD_SEARCHED_USER, payload: { ...searchedUser, Referrals: data.message } });
                        setError("");
                        clearFilters("Tickets");
                    } else {
                        setError("Error in fetching user tickets");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setError("Search input can not be empty");
        }
    };
    const fetchUserLedger = async (e) => {
        if (searchedUser.User["_id"]) {
            if (startDate && endDate) {
                let from = moment(startDate).format("yyyy-MM-DD");
                let to = moment(endDate).format("yyyy-MM-DD");
                await axios({
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        token: token,
                    },
                    url: REACT_APP_API_URL + "/adminPanel/gold/getUserLedger",
                    params: { user: searchedUser.User["_id"], from, to },
                })
                    .then((res) => {
                        var data = res.data;
                        if (data.status === "success") {
                            dispatch({ type: GOLD_SEARCHED_USER, payload: { ...searchedUser, Ledger: data.message } });
                            setError("");
                            clearFilters("Ledger");
                        } else {
                            setError("Error in fetching user ledger");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setDateError("Select dates.");
            }
        } else {
            setError("Search input can not be empty");
        }
    };
    const getFilteredLog = (dataKey) => {
        let value = [...searchedUser[dataKey]];
        let filteredTableValue = value;
        Object.keys(filters[dataKey]).map((key) => {
            if (filters[dataKey][key]) {
                let toPop = [];
                value.map((log) => {
                    if (log[key] !== filters[dataKey][key]) {
                        let index = value.indexOf(log);
                        toPop.push(index);
                    }
                });
                toPop.reverse();
                toPop.map((item) => {
                    filteredTableValue.splice(item, 1);
                });
            }
        });
        return getTable(filteredTableValue, dataKey);
    };
    function getTable(cols, tableType) {
        tables++;
        const columns = [];
        if (tableType === "Tickets") {
            columns.push({ name: "TicketNo.", selector: (row) => row.ticketNumber, sortable: true });
            columns.push({ name: "Mode", selector: (row) => row.mode, sortable: true });
            columns.push({ name: "Numbers", selector: (row) => row.numbers.join(","), sortable: true });
            columns.push({ name: "Status", selector: (row) => row.status, sortable: true });
        } else if (tableType === "Ledger") {
            columns.push({ name: "Type", selector: (row) => row.type, sortable: true });
            columns.push({ name: "Amount", selector: (row) => row.amount, sortable: true });
            columns.push({ name: "Quantity", selector: (row) => row.qty, sortable: true });
            columns.push({ name: "Date", selector: (row) => row.date, sortable: true });
            columns.push({ name: "Status", selector: (row) => row.status, sortable: true });
        } else if (tableType === "Referrals") {
            columns.push({ name: "Name", selector: (row) => row.name, sortable: true });
            columns.push({ name: "Number", selector: (row) => row.phoneNumber, sortable: true });
            columns.push({ name: "Gender", selector: (row) => row.gender, sortable: true });
            columns.push({ name: "Journey Stage", selector: (row) => row.journeyStage, sortable: true });
            columns.push({ name: "State", selector: (row) => row.state, sortable: true });
        }
        // cols.map((item, n) => {
        //     cols[n].name = Category[cols[n]["categoryId"]];
        // });
        return (
            <>
                <DataTable
                    columns={columns}
                    // data={cols.filter((item) => {
                    //     if (filter === "") {
                    //         return item;
                    //     } else if (item.identifier.toLowerCase().includes(filter.toLowerCase())) {
                    //         return item;
                    //     }
                    // })}
                    data={cols}
                    pagination
                    paginationTotalRows={cols.length}
                    theme="solarized"
                    key={tables}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="300px"
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    conditionalRowStyles={tableType === "Tickets" ? conditionalRowStyles.tickets : tableType === "Ledger" ? conditionalRowStyles.ledger : undefined}
                    dense
                />
            </>
        );
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    return (
        <>
            <Container>
                <Search style={boxAround}>
                    <InputBar>
                        <FaIcons.FaSearch />
                        <input ref={searchData} type="text" placeholder="Email Id / Phone Number" />
                    </InputBar>
                    <button className="searchBtn" onClick={onSubmit}>
                        Search
                    </button>
                    <button className="clearBtn" onClick={onClear}>
                        Clear
                    </button>
                    <span>{error}</span>
                </Search>
                {Object.keys(searchedUser).length > 0 && (
                    <>
                        <FetchButtons>
                            <button className="fetchBtn" onClick={fetchLockerDetails}>
                                User Locker
                            </button>
                            <button className="fetchBtn" onClick={fetchUserSummary}>
                                User Summary
                            </button>
                            <button className="fetchBtn" onClick={fetchUserTickets}>
                                Tickets
                            </button>
                            <button className="fetchBtn" onClick={fetchUserReferrals}>
                                Referrals
                            </button>
                        </FetchButtons>
                        <FetchButtons>
                            <DateFilter>
                                <DatePicker
                                    dateFormat="yyyy-MM-dd"
                                    selectsRange={true}
                                    selected={startDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        onChange(update);
                                    }}
                                    isClearable
                                />
                            </DateFilter>
                            <span>{dateError}</span>
                            <button className="fetchBtn" onClick={fetchUserLedger}>
                                Fetch Ledger
                            </button>
                        </FetchButtons>
                        <Data>
                            {Object.entries(searchedUser).map(([dataKey, val]) => {
                                if (dataKey === "Tickets") {
                                    return (
                                        <DataCard style={boxAround} key={dataKey}>
                                            <h1>{dataKey}</h1>
                                            <Filters>
                                                <select id="mode" defaultValue={"mode"} onChange={(e) => setFilters({ ...filters, Tickets: { ...filters.Tickets, mode: e.target.value } })}>
                                                    <option value="mode" disabled>
                                                        Mode
                                                    </option>
                                                    <option value="Referral">Referral</option>
                                                    <option value="Buy Gold">Buy</option>
                                                    <option value="Bonus">Bonus</option>
                                                </select>
                                                <select id="status" defaultValue={"status"} onChange={(e) => setFilters({ ...filters, Tickets: { ...filters.Tickets, status: e.target.value } })}>
                                                    <option value="status" disabled>
                                                        Status
                                                    </option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                    <option value="Expired">Expired</option>
                                                </select>
                                                <button onClick={(e) => clearFilters("Tickets")}>Clear Filters</button>
                                            </Filters>
                                            <span>{getFilteredLog(dataKey)}</span>
                                        </DataCard>
                                    );
                                } else if (dataKey === "Ledger") {
                                    return (
                                        <DataCard style={boxAround} key={dataKey}>
                                            <h1>{dataKey}</h1>
                                            <Filters>
                                                <select id="type" defaultValue={"type"} onChange={(e) => setFilters({ ...filters, Ledger: { ...filters.Ledger, type: e.target.value } })}>
                                                    <option value="type" disabled>
                                                        Type
                                                    </option>
                                                    <option value="Buy">Buy</option>
                                                    <option value="Sell">Sell</option>
                                                    <option value="Reward">Reward</option>
                                                </select>
                                                <select id="ledgerStatus" defaultValue={"status"} onChange={(e) => setFilters({ ...filters, Ledger: { ...filters.Ledger, status: e.target.value } })}>
                                                    <option value="status" disabled>
                                                        Status
                                                    </option>
                                                    <option value="success">Success</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="failure">Failure</option>
                                                </select>
                                                <button onClick={(e) => clearFilters("Ledger")}>Clear Filters</button>
                                            </Filters>
                                            <span>{getFilteredLog(dataKey)}</span>
                                        </DataCard>
                                    );
                                } else if (dataKey === "Referrals") {
                                    return (
                                        <DataCard style={boxAround} key={dataKey}>
                                            <h1>{dataKey}</h1>
                                            {/* <Filters>
                                                <select id="type" defaultValue={"type"} onChange={(e) => setFilters({ ...filters, Referrals: { ...filters.Ledger, type: e.target.value } })}>
                                                    <option value="type" disabled>
                                                        Type
                                                    </option>
                                                    <option value="Buy">Buy</option>
                                                    <option value="Sell">Sell</option>
                                                    <option value="Reward">Reward</option>
                                                </select>
                                                <select
                                                    id="ledgerStatus"
                                                    defaultValue={"status"}
                                                    onChange={(e) => setFilters({ ...filters, Referrals: { ...filters.Ledger, status: e.target.value } })}
                                                >
                                                    <option value="status" disabled>
                                                        Status
                                                    </option>
                                                    <option value="success">Success</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="failure">Failure</option>
                                                </select>
                                                <button onClick={(e) => clearFilters("Referrals")}>Clear Filters</button>
                                            </Filters> */}
                                            <span>{getFilteredLog(dataKey)}</span>
                                        </DataCard>
                                    );
                                } else {
                                    return (
                                        <DataCard style={boxAround} key={dataKey}>
                                            <h1>{dataKey}</h1>
                                            <span>
                                                {Object.entries(val).map(([key, value]) => {
                                                    return (
                                                        <h3 key={key}>
                                                            {key} : {JSON.stringify(value)}
                                                        </h3>
                                                    );
                                                })}
                                            </span>
                                        </DataCard>
                                    );
                                }
                            })}
                        </Data>
                    </>
                )}
            </Container>
        </>
    );
}

export default SearchGoldUser;

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const Search = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4rem;
    align-items: center;
    .searchBtn {
        font-size: 1.3rem;
        background-color: rgb(${DarkColors.themeBlue});
        padding: 8px 15px;
        color: rgb(${DarkColors.themeFont});
        border: 0;
        border-radius: 10px;
        :hover {
            cursor: pointer;
            background-color: rgba(${DarkColors.themeBlue}, 0.7);
        }
    }
    .clearBtn {
        font-size: 1.3rem;
        background-color: rgb(${DarkColors.themeRed});
        padding: 8px 15px;
        color: rgb(${DarkColors.themeFont});
        border: 0;
        border-radius: 10px;
        :hover {
            cursor: pointer;
            background-color: rgba(${DarkColors.themeRed}, 0.7);
        }
    }
    @media only screen and (max-width: 550px) {
        flex-direction: column;
        gap: 5px;
    }
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
`;

const InputBar = styled.div`
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: row;
    gap: 20px;
    input {
        @media only screen and (max-width: 550px) {
            input {
                width: 100%;
                /* padding: 8px 5px; */
            }
        }
        width: 100%;
        font-size: 1.3rem;
        border: none;
        border-radius: 10px;
        padding: 8px 50px;
        :focus {
            outline: none;
        }
    }
    svg {
        @media only screen and (max-width: 1000px) {
            display: none;
        }
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: rgb(${DarkColors.themeBackgroundSemiDark});
    }
    button {
        font-size: 1.3rem;
        background-color: rgb(${DarkColors.themeFont});
        padding: 6px 13px;
        color: rgb(${DarkColors.themeBackgroundSemiDark});
        border: 0;
        border-radius: 8px;
        :hover {
            cursor: pointer;
            background-color: rgba(${DarkColors.themeFont}, 0.8);
        }
    }
`;

const Data = styled.div`
    /* width: 1500px; */
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const DataCard = styled.div`
    overflow-x: auto;
`;

const FetchButtons = styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    gap: 20px;
    .fetchBtn {
        padding: 20px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(${DarkColors.themeFont}, 0.8);
        border-radius: 8px;
        border-color: rgba(${DarkColors.themeFont}, 0.8);
        background-color: rgba(${DarkColors.themeBackgroundSemiDark}, 0.5);
        :hover {
            border-color: rgba(${DarkColors.themeFont}, 1op);
            color: rgba(${DarkColors.themeFont}, 1);
            background-color: rgba(${DarkColors.themeBackgroundSemiDark}, 1);
            cursor: pointer;
        }
    }
`;
const UserDetails = styled.div`
    flex: 1;
    gap: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

const DateFilter = styled.div`
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: fit-content;
    input {
        margin-right: 50px;
        margin-top: 5px;
        width: auto;
        border: 0;
        :focus {
            outline: 0;
        }
    }
    button {
        margin-left: 20px;
    }
`;
