import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { DarkColors } from "../../../assets/styles/sharedCss";

const NavbarMenu = ({ item }) => {
    const auth = useSelector((state) => state?.userResponse?.user);
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);
    return (
        <>
            {auth?.roles?.find((role) => item.roles?.includes(role)) && (
                <>
                    <SidebarLink to={item.path} onClick={item.subNav && showSubnav} target={item.target} rel={item.target ? "noreferrer noopener" : null}>
                        <div>
                            {item.icon}
                            <SidebarLabel>{item.title}</SidebarLabel>
                        </div>
                        <div>{item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}</div>
                    </SidebarLink>
                    {subnav &&
                        item.subNav.map((item, index) => {
                            return (
                                <div key={index}>
                                    {auth?.roles?.find((role) => item.roles?.includes(role)) && (
                                        <DropdownLink to={item.path} target={item.target} rel={item.target ? "noopener noreferrer" : null}>
                                            {item.icon}
                                            <SidebarLabel>{item.title}</SidebarLabel>
                                        </DropdownLink>
                                    )}
                                </div>
                            );
                        })}
                </>
            )}
        </>
    );
};

export default NavbarMenu;

const SidebarLink = styled(Link)`
    display: flex;
    color: rgba(255, 255, 255, 0.8);

    justify-content: space-between;
    align-items: center;
    list-style: none;
    height: 35px;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    padding: 5px;
    transition: background 0.3s ease-in-out;
    &:hover {
        background-color: rgba(${DarkColors.themeBackgroundDark}, 0.3);

        color: rgba(255, 255, 255, 1);
        cursor: pointer;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    background: rgba();
    height: 30px;
    padding-left: 2rem;
    transition: background 0.2s ease-in-out;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    font-size: 18px;

    &:hover {
        background-color: rgba(${DarkColors.themeBackgroundDark}, 0.3);
        color: rgba(255, 255, 255, 1);

        cursor: pointer;
    }
`;
