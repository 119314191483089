import React, { useEffect, useRef, useState } from "react";
import { DarkColors, boxAround } from "../../../assets/styles/sharedCss";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import styled from "styled-components";
const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

function GoldApiLogs(props) {
    const logString = useSelector((state) => state.logResponse.goldApiLogs?.data);
    const [tableData, setTableData] = useState();
    const searchFilter = useRef();
    const erroredLogsOnly = useRef(false);
    var tables = 1;
    const [allLogs, setAllLogs] = useState();
    const conditionalRowStyles = [
        {
            when: (row) => row.level === "error",
            style: {
                backgroundColor: `#b8000053`,
                color: "white",
            },
        },
    ];

    useEffect(() => {
        logString && combineAllogs();
    }, [logString]);

    useEffect(() => {
        allLogs && computeShowLogs();
    }, [allLogs]);

    const searchHandler = (e) => {
        let value = e.target.value.trim();
        searchFilter.current = value;
        computeShowLogs(value);
    };

    const erroredLogsOnlyHandler = (e) => {
        let value = e.target.checked;
        erroredLogsOnly.current = value;
        computeShowLogs();
    };

    function computeShowLogs(search = "") {
        var data = {};
        for (let n in allLogs) {
            // looping through each log
            let log = JSON.parse(allLogs[n]); // parsing each log since its stored as a string
            if (erroredLogsOnly.current) {
                if (log.level !== "error") {
                    continue;
                }
            }
            let [service, method, ...identifier] = log.message.split(".");
            // let [service2, method2, ...identifier2] = log.message.split(".");

            // service === "MIDDLEWARE" && console.log(identifier2.join("."));
            if (!searchFilter.current || identifier.includes(searchFilter.current.toLowerCase())) {
                if (data.hasOwnProperty(service)) {
                    if (data[service].hasOwnProperty(method)) {
                        data[service][method].push({
                            timestamp: log.timestamp,
                            apiTracer: log.apiTracer,
                            level: log.level,
                            identifier: identifier.toString().replace(/,/g, "."),
                            request: log.request ? log.request : "No data",
                            response: log.response ? log.response : "No data",
                            error: log.error ? log.error : "No data",
                        });
                    } else {
                        data[service] = {
                            ...data[service],
                            [method]: [
                                {
                                    timestamp: log.timestamp,
                                    apiTracer: log.apiTracer,
                                    level: log.level,
                                    identifier: identifier.toString().replace(/,/g, "."),
                                    request: log.request ? log.request : "No data",
                                    response: log.response ? log.response : "No data",
                                    error: log.error ? log.error : "No data",
                                },
                            ],
                        };
                    }
                } else {
                    data[service] = {
                        ...data[service],
                        [method]: [
                            {
                                timestamp: log.timestamp,
                                apiTracer: log.apiTracer,
                                level: log.level,
                                identifier: identifier.toString().replace(/,/g, "."),
                                request: log.request ? log.request : "No data",
                                response: log.response ? log.response : "No data",
                                error: log.error ? log.error : "No data",
                            },
                        ],
                    };
                }
            }
        }
        setTableData(data);
    }

    function combineAllogs() {
        //Looping through each days of logs
        var logObj = JSON.parse(logString);
        var tempAllLogs = [];
        for (let day in logObj) {
            let eachDayLog = logObj[day].split(/\r?\n/);
            eachDayLog.pop(); // to remove last element since its always empty
            tempAllLogs = eachDayLog;
        }
        setAllLogs(tempAllLogs);
    }

    function getTable(cols) {
        tables++;

        const columns = [
            {
                name: "TimeStamp",
                selector: (row) => row.timestamp,
                sortable: true,
            },
            {
                name: "Level",
                selector: (row) => row.level,
                sortable: true,
            },
            {
                name: "Identifier",
                selector: (row) => row.identifier,
                sortable: true,
            },
            {
                name: "Tracer",
                selector: (row) => row.apiTracer,
                sortable: true,
            },
        ];
        return (
            <>
                <DataTable
                    columns={columns}
                    // data={cols.filter((item) => {
                    //     if (filter === "") {
                    //         return item;
                    //     } else if (item.identifier.toLowerCase().includes(filter.toLowerCase())) {
                    //         return item;
                    //     }
                    // })}
                    data={cols}
                    pagination
                    theme="solarized"
                    key={tables}
                    fixedHeader={true}
                    fixedHeaderScrollHeight="300px"
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    conditionalRowStyles={conditionalRowStyles}
                    dense
                />
            </>
        );
    }
    function dropDownVendor(e) {
        let routeCollumn = document.getElementById(e);
        let classes = routeCollumn.className.split(" ");
        if (classes[1] === "showRoute") {
            routeCollumn.classList.remove("showRoute");
        } else {
            routeCollumn.classList.add("showRoute");
        }
    }
    function dropDownMethod(e) {
        let routeCollumn = document.getElementById(e);
        let classes = routeCollumn.className.split(" ");
        if (classes[1] === "showRoute") {
            routeCollumn.classList.remove("showRoute");
        } else {
            routeCollumn.classList.add("showRoute");
        }
    }
    function dropDownTable(e) {
        // setFilter("");
        let routeCollumn = document.getElementById(e);
        let classes = routeCollumn.className.split(" ");
        if (classes[1] === "showRoute") {
            routeCollumn.classList.remove("showRoute");
        } else {
            routeCollumn.classList.add("showRoute");
        }
    }
    return (
        <ApiLogsWrapper>
            <Container>
                <ApiLogWrapper>
                    {tableData && (
                        <>
                            <Filters>
                                <input type="text" placeholder="Search with an identifier" value={searchFilter.current} onChange={(e) => searchHandler(e)} />
                                <div>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => {
                                            erroredLogsOnlyHandler(e);
                                        }}
                                        name="error"
                                    />
                                    <label htmlFor="error">Show only errored logs</label>
                                </div>
                            </Filters>
                            {Object.entries(tableData).map(([vendor, routes]) => {
                                return (
                                    <div className="mainVendor" style={boxAround} key={vendor}>
                                        <div className="vendor" onClick={(e) => dropDownVendor(vendor)}>
                                            {vendor}
                                        </div>
                                        <div className="routes" id={vendor}>
                                            {Object.entries(routes).map(([route, methods]) => {
                                                return (
                                                    <React.Fragment key={route}>
                                                        <div key={vendor + route} className="route">
                                                            <div className="route-header" onClick={(e) => dropDownMethod(vendor + route)}>
                                                                <span>{route}</span>
                                                                {/* <span className="apiAccessedNumber">{Object.keys(methods).length}</span> */}
                                                            </div>
                                                            <div className="accessed showRoute" id={vendor + route}>
                                                                {getTable(methods)}
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    )}
                </ApiLogWrapper>
            </Container>
        </ApiLogsWrapper>
    );
}

export default GoldApiLogs;

const ApiLogsWrapper = styled.div`
    flex: 1;
`;

const Container = styled.div`
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const ApiLogWrapper = styled.div`
    width: clamp(300px, 100%, 1000px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    .mainVendor {
        /* background-color: rgb(${DarkColors.themeBackgroundSemiDark});
        padding: 15px 20px;
        border-radius: 15px; */
        .showRoute {
            display: none !important;
        }
        .vendor {
            color: rgba(${DarkColors.themeFont}, 0.7);
            border-radius: 8px;
            padding: 10px;
            font-weight: 700;
            font-size: 1.2rem;
            :hover {
                background-color: rgba(${DarkColors.themeBlue}, 0.1);
                cursor: pointer;
            }
        }

        .routes {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .route {
                display: flex;
                flex-direction: column;
                gap: 6px;
                color: rgba(${DarkColors.themeFont}, 0.8);
                .route-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 10px 15px;
                    .apiAccessedNumber {
                        font-weight: 700;
                    }
                    :hover {
                        color: rgba(${DarkColors.themeFont}, 0.95);

                        background-color: rgba(${DarkColors.themeBlue}, 0.3);
                        border-radius: 4px;
                        cursor: pointer;
                    }
                }
            }
            .methods {
                .methodsContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    .method {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 10px 25px;
                        color: rgba(${DarkColors.themeFont}, 0.7);
                        :hover {
                            color: rgba(${DarkColors.themeFont}, 0.9);
                            background-color: rgba(${DarkColors.themeBlue}, 0.3);
                            border-radius: 4px;
                            cursor: pointer;
                        }
                    }
                    .apiAccessedNumber {
                        font-weight: 700;
                    }
                    .accessed {
                        display: flex;
                        flex-direction: column;
                        gap: 3px;
                        .rdt_Table {
                            .rdt_TableHead {
                                font-size: 1rem !important;
                                .rdt_TableHeadRow {
                                    min-height: auto;
                                    .rdt_TableCol {
                                        padding: 5px 16px;
                                    }
                                }
                            }
                            .rdt_TableRow {
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    gap: 30px;
    input[type="text"] {
        flex: 1;
    }
`;
